export const SERVICE_MENU_LIST_LOADING = "SERVICE_MENU_LIST_LOADING";
export const GET_SERVICE_MENU_LIST = "GET_SERVICE_MENU_LIST";
export const GET_SERVICE_MENU_LIST_ERROR = "GET_SERVICE_MENU_LIST_ERROR";

export const GET_SERVICE_MENU_BY_ID_LOADING = "GET_SERVICE_MENU_BY_ID_LOADING";
export const GET_SERVICE_MENU_BY_ID_SUCCESS = "GET_SERVICE_MENU_BY_ID_SUCCESS";
export const GET_SERVICE_MENU_BY_ID_ERROR = "GET_SERVICE_MENU_BY_ID_ERROR";

export const DELETEING_BULK_SERVICE_MENU = "DELETEING_BULK_SERVICE_MENU";
export const DELETE_BULK_SERVICE_MENU = "DELETE_BULK_SERVICE_MENU";
export const DELETE_BULK_SERVICE_MENU_ERROR = "DELETE_BULK_SERVICE_MENU_ERROR" 

export const DELETED_BULK_SERVICE_MENU_LIST_LOADING = "DELETED_BULK_SERVICE_MENU_LIST_LOADING";
export const GET_DELETED_BULK_SERVICE_MENU_LIST = "GET_DELETED_BULK_SERVICE_MENU_LIST";
export const GET_DELETED_BULK_SERVICE_MENU_LIST_ERROR = "GET_DELETED_BULK_SERVICE_MENU_LIST_ERROR";

export const RESTORE_BULK_SERVICE_MENU_LIST_LOADING = "RESTORE_BULK_SERVICE_MENU_LIST_LOADING";
export const RESTORE_BULK_SERVICE_MENU_LIST = "RESTORE_BULK_SERVICE_MENU_LIST";
export const RESTORE_BULK_SERVICE_MENU_LIST_ERROR = "RESTORE_BULK_SERVICE_MENU_LIST_ERROR";

export const PERMA_DELETEING_BULK_SERVICE_MENU = "PERMA_DELETEING_BULK_SERVICE_MENU";
export const PERMA_DELETE_BULK_SERVICE_MENU = "PERMA_DELETE_BULK_SERVICE_MENU";
export const PERMA_DELETE_BULK_SERVICE_MENU_ERROR = "PERMA_DELETE_BULK_SERVICE_MENU_ERROR" 
