import React from 'react';
import DashboardLayout from "../Components/Layouts/DashboardLayout";
import RecentUpdates from "../Components/RecentUpdates";

const PageContent = () => {
  return (
    <div className="page-content">
      <div className="container-fluid">
        <RecentUpdates limit={15}/>
      </div>
    </div>
  );
};

export default function AllLogs({ useAuth }) {
  return (
    <DashboardLayout useAuth={useAuth}>
      <PageContent />
    </DashboardLayout>
  );
}
