import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export async function getCroppedImg(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext("2d");

  ctx.fillStyle = "#ffffff";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });
}

const App = ({ image, setCroppedImage, setImageCropOpen, height, width }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loaded_image, setLoadedImage] = useState(undefined);
  const onCropComplete = useCallback((croppedArea, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
    console.log(croppedArea, croppedAreaPixels)
  }, []);

  let imageDataUrl = async () => {
    let temp = await readFile(image);
    setLoadedImage(temp);
  };

  useEffect(() => {
    imageDataUrl();
  }, []);

  let cropSize = {
    height: parseFloat(height),
    width: parseFloat(width),
  };

  const showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(loaded_image, croppedAreaPixels);
    setCroppedImage(croppedImage);
    setImageCropOpen(false);
  };

  return (
    <div style={pageStyles["App"]}>
      <div style={pageStyles["crop-container"]}>
        <Cropper
          image={loaded_image}
          crop={crop}
          zoom={zoom === 0 ? 1 : zoom}
          aspect={parseFloat(width) / parseFloat(height)}
          cropSize={cropSize}
          minZoom={0.5}
          restrictPosition={false}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
        <img src={crop} />
      </div>
      <div style={pageStyles["controls"]}>
        <div style={{ width: 300, margin: "auto" }}>
          <input
            type="range"
            className="form-range"
            id="customRange1"
            onChange={(e, zoom) => {
              setZoom(e.target.value / 30);
            }}
            value={zoom * 30}
          />
        </div>
        <div style={{ width: 210, margin: "auto" }}>
          <button
            type="button"
            class="btn btn-primary btn-sm"
            style={{ width: 100, marginRight: 10 }}
            onClick={showCroppedImage}
          >
            Crop
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm"
            style={{ width: 100 }}
            onClick={() => setImageCropOpen(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const pageStyles = {
  App: {
    position: "fixed",
    height: "100vh",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
  },
  "crop-container": {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  controls: {
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    height: "80px",
    width: "100%",
  },
};

export default App;
