export const ROOM_LIST_LOADING = "ROOM_LIST_LOADING";
export const GET_ROOM_LIST = "GET_ROOM_LIST";
export const GET_ROOM_LIST_ERROR = "GET_ROOM_LIST_ERROR";

export const GET_ROOM_BY_ID_LOADING = "GET_ROOM_BY_ID_LOADING";
export const GET_ROOM_BY_ID_SUCCESS = "GET_ROOM_BY_ID_SUCCESS";
export const GET_ROOM_BY_ID_ERROR = "GET_ROOM_BY_ID_ERROR";

export const DELETEING_ROOM = "DELETEING_ROOM";
export const DELETE_ROOM = "DELETE_ROOM";
export const DELETE_ROOM_ERROR = "DELETE_ROOM_ERROR" 

export const DELETED_ROOM_LIST_LOADING = "DELETED_ROOM_LIST_LOADING";
export const GET_DELETED_ROOM_LIST = "GET_DELETED_ROOM_LIST";
export const GET_DELETED_ROOM_LIST_ERROR = "GET_DELETED_ROOM_LIST_ERROR";

export const RESTORE_ROOM_LIST_LOADING = "RESTORE_ROOM_LIST_LOADING";
export const RESTORE_ROOM_LIST = "RESTORE_ROOM_LIST";
export const RESTORE_ROOM_LIST_ERROR = "RESTORE_ROOM_LIST_ERROR";

export const PERMA_DELETEING_ROOM = "PERMA_DELETEING_ROOM";
export const PERMA_DELETE_ROOM = "PERMA_DELETE_ROOM";
export const PERMA_DELETE_ROOM_ERROR = "PERMA_DELETE_ROOM_ERROR" 

export const SORTING_ROOM = "SORTING_ROOM";
export const SORT_ROOM = "SORT_ROOM";
export const ROOM_SORT_ERROR = "ROOM_SORT_ERROR" 
