import {
  ROOM_LIST_LOADING,
  GET_ROOM_LIST_ERROR,
  GET_ROOM_LIST,
  GET_ROOM_BY_ID_LOADING,
  GET_ROOM_BY_ID_SUCCESS,
  GET_ROOM_BY_ID_ERROR,
  DELETE_ROOM,
  DELETE_ROOM_ERROR,
  DELETEING_ROOM,
  DELETED_ROOM_LIST_LOADING,
  GET_DELETED_ROOM_LIST,
  GET_DELETED_ROOM_LIST_ERROR,
  RESTORE_ROOM_LIST_LOADING,
  RESTORE_ROOM_LIST,
  RESTORE_ROOM_LIST_ERROR,
  PERMA_DELETE_ROOM,
  PERMA_DELETE_ROOM_ERROR,
  PERMA_DELETEING_ROOM,  
  SORTING_ROOM,
  SORT_ROOM,
  ROOM_SORT_ERROR,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ROOM_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_ROOM_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETED_ROOM_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETEING_ROOM:
      return {
        ...state,
        loading: true,
        error: false,
      };

      case SORTING_ROOM:
        return {
          ...state,
          loading: true,
          error: false,
        };

    case RESTORE_ROOM_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case PERMA_DELETEING_ROOM:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_ROOM_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_ROOM_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };

    case DELETE_ROOM:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case GET_DELETED_ROOM_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case RESTORE_ROOM_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case PERMA_DELETE_ROOM:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
      case SORT_ROOM:
        return {
          ...state,
          loading: false,
          data: action.payload.data,
          error: false,
        };
    case GET_ROOM_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_ROOM_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_DELETED_ROOM_LIST_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case DELETE_ROOM_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case RESTORE_ROOM_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case PERMA_DELETE_ROOM_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

      case ROOM_SORT_ERROR:
        return {
          ...state,
          data: undefined,
          loading: false,
          error: action.payload.data,
        };

    default:
      return state;
  }
};

export default reducer;
