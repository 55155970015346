export const ALBUM_LIST_LOADING = "ALBUM_LIST_LOADING";
export const GET_ALBUM_LIST = "GET_ALBUM_LIST";
export const GET_ALBUM_LIST_ERROR = "GET_ALBUM_LIST_ERROR";

export const GET_ALBUM_BY_ID_LOADING = "GET_ALBUM_BY_ID_LOADING";
export const GET_ALBUM_BY_ID_SUCCESS = "GET_ALBUM_BY_ID_SUCCESS";
export const GET_ALBUM_BY_ID_ERROR = "GET_ALBUM_BY_ID_ERROR";

export const DELETEING_ALBUM = "DELETEING_ALBUM";
export const DELETE_ALBUM = "DELETE_ALBUM";
export const DELETE_ALBUM_ERROR = "DELETE_ALBUM_ERROR";

export const DELETED_ALBUM_LIST_LOADING = 'DELETED_ALBUM_LIST_LOADING';
export const GET_DELETED_ALBUM_LIST= 'GET_DELETED_ALBUM_LIST';
export const GET_DELETED_ALBUM_LIST_ERROR = 'GET_DELETED_ALBUM_LIST_ERROR';

export const PERMA_DELETEING_ALBUM = 'PERMA_DELETEING_ALBUM';
export const PERMA_DELETE_ALBUM = 'PERMA_DELETE_ALBUM';
export const PERMA_DELETE_ALBUM_ERROR = 'PERMA_DELETE_ALBUM_ERROR';

export const RESTORE_ALBUM_LIST_LOADING = 'RESTORE_ALBUM_LIST_LOADING';
export const RESTORE_ALBUM_LIST = 'RESTORE_ALBUM_LIST';
export const RESTORE_ALBUM_LIST_ERROR = 'RESTORE_ALBUM_LIST_ERROR';
