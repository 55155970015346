import {
  CREATING_SERVICE_MENU_TYPE,
  ERROR_SERVICE_MENU_TYPE_CREATE,
  UPDATING_SERVICE_MENU_TYPE,
  SERVICE_MENU_TYPE_CREATED,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE,
  SERVICE_MENU_TYPE_UPDATED,
  ERROR_SERVICE_MENU_TYPE_UPDATE,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  loadedRecently: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATING_SERVICE_MENU_TYPE:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case SERVICE_MENU_TYPE_CREATED:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case ERROR_SERVICE_MENU_TYPE_CREATE:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

    case UPDATING_SERVICE_MENU_TYPE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case SERVICE_MENU_TYPE_UPDATED:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };
    case ERROR_SERVICE_MENU_TYPE_UPDATE:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case SET_UPDATED_RECENTLY_TO_FALSE:
      return {
        ...state,
        loadedRecently: false
      }
    case UPDATED_RECENTLY:
      return {
        ...state,
        loadedRecently: true
      }
    default:
      return state;
  }
};

export default reducer;
