
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DashboardList from "../../../Components/Layouts/DashboardList";
import { permanentDeleteEntity, getDeletedList, restoretDeleteEntity } from "../../../Redux/Hotel/Package/actions";

function DeletedList({
  useAuth,
  getList,
  list,
  loginData,
  onDelete,
  onRestore
}) {
  let tokenData = loginData.data;
  const [filter_options, setFilterOptions] = useState();

  useEffect(() => {
    if (!list.data) {
      getList(tokenData);
    }
  }, [list.data]);

  const tableConfig = {
    title: "Deleted Packages",
    filterOptionTitle: "Category",
    filterOptions: filter_options,
    applyFilter: false,
    tableColumns: [
      { name: "Status", selector: "status", width: "3%" },
      { name: "Order", selector: "order", width: "3%" },
      { name: "Title", selector: "package_title", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "17%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "title",
    searchByColumn: "title",
    updatePath: "/package/update",
    addPath: "/package/add",
    noDataMessage: "No Package exists",
    updateLinkColumn: "package_title",
    deleteBtnText: "Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList)
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList)
    },
  };

  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.packages,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getDeletedList(tokenData)),
    onDelete: (idList) => dispatch(permanentDeleteEntity(idList)),
    onRestore: (idList) => dispatch(restoretDeleteEntity(idList)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletedList);

