import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Col, Row, Switch, Alert, Spin } from "antd";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import DashboardActionBar from "../DashboardActionBar";

const MenuTypeForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
}) => {
  let history = useHistory();
  const tokenData = loginData.data;
  const [form] = Form.useForm();
  const { validateFields, setFieldsValue, getFieldValue } = form;

  const [description, setDescription] = useState(
    isUpdateForm && data.dataById && data.dataById.data.type_description
  );

  const [has_update_form_values, setUpdateFormValues] = useState(false);
  const loading = selectedForm.loading || data.loading;
  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
  }, []);

  useEffect(() => {
    const alert = document.querySelector(".custom-alert");
    setTimeout(() => {
      alert.classList.add("fadeout");
    }, 5000);
  }, [selectedForm.error]);

  const menuTypeAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (action === "add") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData
        );
      } else if (action === "addandexit") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData,
          true,
          history
        );
      } else if (action === "update") {
        updateEntityInfo(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData,
          params.id
        ).then(() => loadEntityById());
      }
    });
  };

  const addNewEntry = (e) => {
    menuTypeAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    menuTypeAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    menuTypeAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
  };

  if (isUpdateForm) {
    setFieldsValue({
      title: data.dataById?.data.type_title,
      type_key: data.dataById?.data.type_key,
      status: data.dataById?.data.status == 1 ? true : false,
      description: data.dataById?.data.type_description,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add Menu Type",
    titleToUpdate: "Update Menu Type",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Menu Type",
    taskCompleteAddMessage: "Menu Type is added!",
    taskCompleteUpdateMessage: "Menu Type is updated!",
    discardLink: "manage-menu",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <Form
      form={form}
      size="large"
      layout="vertical"
      className="custom-form"
      wrapperCol={{
        flex: 1,
      }}
    >
      <DashboardActionBar {...{ actionBarOptions }} />

      {selectedForm?.error && (
        <Alert
          className="custom-alert"
          message="Error"
          showIcon
          description={[
            <ul>
              {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                return <li key={idx}>{itm}</li>;
              })}
            </ul>,
          ]}
          type="error"
          closable
        />
      )}
      <Spin spinning={loading} delay={500}>
        <div className="common-module">
          <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Title is required",
                  },
                  { min: 3, max: 255, message: "Invalid Name" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Menu Type Key"
                name="type_key"
                rules={[
                  {
                    required: true,
                    message: "Please input your type key",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <div className="switch-group">
                <Form.Item label="Status" valuePropName="checked" name="status">
                  <Switch
                    checkedChildren="Publish"
                    unCheckedChildren="Unpublish"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col className="gutter-row" xs={24}>
              <Form.Item label="Description" name="description">
                <TextEditor
                  value={description}
                  initialValue={isUpdateForm && description}
                  onEditorChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Spin>
    </Form>
  );
};

export default MenuTypeForm;
