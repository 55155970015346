import React, { useEffect } from "react";
import { Form, Input, Col, Row, Switch, Select, Alert, Spin } from "antd";
import DashboardActionBar from "../../DashboardActionBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
const AmenitiesForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading = selectedForm.loading || data.loading

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id)
  }
  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById()
    }
  }, []);

  useEffect(() => {
    const alert = document.querySelector('.custom-alert')
    setTimeout(() => {
      alert.classList.add('fadeout')
    }, 5000)

  }, [selectedForm.error])

  const selectOptionsData = [
    {
      label: 'Wifi',
      value: 'Wifi'
    },
    {
      label: 'Wardrobe',
      value: 'wardrobe'
    },
    {
      label: 'No Smoking',
      value: 'no_smoking'
    },
    {
      label: 'Smoking',
      value: 'smoking'
    },
    {
      label: 'AC',
      value: 'ac'
    },
    {
      label: 'Tea/Coffee Maker',
      value: 'coffee'
    },
    {
      label: 'Mini Bar',
      value: 'bar'
    },
    {
      label: 'Safety Locker',
      value: 'locker'
    },
    {
      label: 'TV',
      value: 'tv'
    },
    {
      label: 'Telephone',
      value: 'telephone'
    },
    {
      label: 'Desk',
      value: 'desk'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ];

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (action === "add") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData
        );
      } else if (action === "addandexit") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData,
          true,
          history
        );
      } else if (action === "update") {
        updateEntityInfo(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData,
          params.id
        ).then(() => loadEntityById());
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
  };

  if (isUpdateForm) {
    setFieldsValue({
      facility_title: data.dataById?.data.facility_title,
      facility_short_description: data.dataById?.data.facility_short_description,
      facility_icon: data.dataById?.data.facility_icon,
      status: data.dataById?.data.status,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add Amenity",
    titleToUpdate: "Update Amenity",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update Amenity",
    taskCompleteAddMessage: "Amenity is added!",
    taskCompleteUpdateMessage: "Amenity is updated!",
    discardLink: "hotel/amenities",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />
        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module mt-3 bg-white">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={13}>
                <Form.Item
                  label="Title"
                  name="facility_title"
                  rules={[
                    {
                      required: true,
                      message: "Title is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={13}>
                <Form.Item
                  label="Key"
                  name="facility_icon"
                >
                  <Select showSearch
                    placeholder="Select a Key"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={selectOptionsData} >

                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={18}>
                <Form.Item
                  label="Short Description"
                  name="facility_short_description"
                  rules={[
                    {
                      required: true,
                      message: "Short description is required",
                    },
                  ]}
                >
                  <TextArea rows="6" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <div className="switch-group">
                  <Form.Item label="Status" valuePropName="checked" name="status">
                    <Switch
                      checkedChildren="Publish"
                      unCheckedChildren="Unpublish"
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default AmenitiesForm;
