import React, { useState, useEffect } from "react";
import { Form, Input, Select, Col, Row, Switch, Alert, Spin } from "antd";
import { useHistory } from "react-router-dom";
import DashboardActionBar from "../DashboardActionBar";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;

const SettingForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading = selectedForm.loading || data.loading;

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
  }, []);

  useEffect(() => {
    const alert = document.querySelector(".custom-alert");
    setTimeout(() => {
      alert.classList.add("fadeout");
    }, 5000);
  }, [selectedForm.error]);

  const settingDataType = [
    { label: "String", value: "string" },
    { label: "Text", value: "text" },
    { label: "Integer", value: "integer" },
    { label: "Decimal", value: "decimal" },
    { label: "File", value: "file" },
    { label: "Image", value: "image" },
    { label: "Date", value: "date" },
  ];

  const settingCategories = [
    { label: "Company", value: "company" },
    { label: "Images", value: "images" },
    { label: "Advanced Settings", value: "advanced" },
  ];

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      values.setting_status = values.setting_status ? 1 : 0;
      values.setting_protection = values.setting_protection ? 1 : 0;
      if (action === "add") {
        createEntity(
          {
            ...values,
          },
          tokenData
        );
      } else if (action === "addandexit") {
        createEntity(
          {
            ...values,
          },
          tokenData,
          true,
          history
        );
      } else if (action === "update") {
        updateEntityInfo(
          {
            ...values,
          },
          tokenData,
          params.id
        ).then(() => loadEntityById());
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
  };

  if (isUpdateForm) {
    setFieldsValue({
      setting_category: data.dataById?.data.key_group,
      setting_key: data.dataById?.data.global_key,
      setting_label: data.dataById?.data.key_label,
      setting_protection: data.dataById?.data.is_protected == 1 ? true : false,
      setting_status: data.dataById?.data.status == 1 ? true : false,
      setting_data_type: data.dataById?.data.value_type,
      setting_description: data.dataById?.data.description,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add Setting",
    titleToUpdate: "Update Setting",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update Setting",
    taskCompleteAddMessage: "Setting is added!",
    taskCompleteUpdateMessage: "Setting is updated!",
    discardLink: "settings",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module bg-white mt-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Setting Category"
                  name="setting_category"
                  rules={[
                    {
                      required: true,
                      message: "Category is required",
                    },
                  ]}
                >
                  <Select>
                    {settingCategories.map((itm, idx) => (
                      <Option key={idx} value={itm.value}>
                        {itm.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Label"
                  name="setting_label"
                  rules={[
                    {
                      required: true,
                      message: "label is required!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Key"
                  name="setting_key"
                  rules={[
                    {
                      required: true,
                      message: "Setting key is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Setting Data Type"
                  name="setting_data_type"
                  rules={[
                    {
                      required: true,
                      message: "Setting Data Type is required",
                    },
                  ]}
                >
                  <Select>
                    {settingDataType.map((itm, idx) => (
                      <Option key={idx} value={itm.value}>
                        {itm.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <div className="switch-group">
                  <Form.Item
                    label="Status"
                    valuePropName="checked"
                    name="setting_status"
                  >
                    <Switch
                      checkedChildren="Active"
                      unCheckedChildren="Not Active"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Protection"
                    valuePropName="checked"
                    name="setting_protection"
                  >
                    <Switch
                      checkedChildren="Protected"
                      unCheckedChildren="Not Protected"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} xl={24}>
                <Form.Item label="Help Text" name="setting_description">
                  <TextArea />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default SettingForm;
