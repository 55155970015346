import {
  SETTINGS_LIST_LOADING,
  GET_SETTINGS_LIST,
  GET_SETTINGS_LIST_ERROR,
  GET_SETTINGS_BY_ID_LOADING,
  GET_SETTINGS_BY_ID_SUCCESS,
  GET_SETTINGS_BY_ID_ERROR,
  DELETEING_SETTING,
  DELETE_SETTING,
  DELETE_SETTING_ERROR,
  UPDATING_SETTING,
  SETTING_UPDATED,
  ERROR_SETTING_UPDATE,
  SET_UPDATED_RECENTLY_TO_FALSE
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const setUpdatedRecentlyToFalse = () => async (dispatch) => {
  dispatch({
    type: SET_UPDATED_RECENTLY_TO_FALSE,
  });
}

export const getList = (tokenData) => async (dispatch) => {
  dispatch({
    type: SETTINGS_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/global/all`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_SETTINGS_LIST,
        payload: response,
      })      
    })
    .catch(function (error) {
      dispatch({
        type: GET_SETTINGS_LIST_ERROR,
        payload: error.response,
      })      
    });
};

export const getEntityById = (tokenData, id) => async (dispatch) => {
  dispatch({
    type: GET_SETTINGS_BY_ID_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/global/single/${id}`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_SETTINGS_BY_ID_SUCCESS,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_SETTINGS_BY_ID_ERROR,
        payload: error.response,
      });
    });
};


export const deleteEntityById = (id) => async (dispatch) => {
  dispatch({
    type: DELETEING_SETTING,
  });
  await axios
    .post(`${BASE_URL}api/v1/admin/global/delete/${id}`, {"_method": "delete"}, {
      headers: {
        'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: DELETE_SETTING,
        payload: response,
      })      
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: DELETE_SETTING_ERROR,
        payload: error.response,
      })      
      message.error(error.response.data.message);
    });
};

export const updateEntityById = (formData, tokenData) => async (dispatch) => {
  dispatch({
    type: UPDATING_SETTING,
  });

  let webKitFormData = new FormData();

  Object.keys(formData).forEach((item, i) => {
    webKitFormData.append(item, formData[item])
  });  
  webKitFormData.append('_method', 'put')

  await axios
    .post(`${BASE_URL}api/v1/admin/global/update`, webKitFormData, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: SETTING_UPDATED,
        payload: response,
      })      
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: ERROR_SETTING_UPDATE,
        payload: error.response,
      })     
      message.error(error.response.data.message);
    });
};

