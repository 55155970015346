export const MEDIA_LIST_LOADING = "MEDIA_LIST_LOADING";
export const GET_MEDIA_LIST = "GET_MEDIA_LIST";
export const GET_MEDIA_LIST_ERROR = "GET_MEDIA_LIST_ERROR";

export const GET_MEDIA_BY_ID_LOADING = "GET_MEDIA_BY_ID_LOADING";
export const GET_MEDIA_BY_ID_SUCCESS = "GET_MEDIA_BY_ID_SUCCESS";
export const GET_MEDIA_BY_ID_ERROR = "GET_MEDIA_BY_ID_ERROR";

export const DELETEING_MEDIA = "DELETEING_MEDIA";
export const DELETE_MEDIA = "DELETE_MEDIA";
export const DELETE_MEDIA_ERROR = "DELETE_MEDIA_ERROR";

export const DELETED_MEDIA_LIST_LOADING = 'DELETED_MEDIA_LIST_LOADING';
export const GET_DELETED_MEDIA_LIST= 'GET_DELETED_MEDIA_LIST';
export const GET_DELETED_MEDIA_LIST_ERROR = 'GET_DELETED_MEDIA_LIST_ERROR';

export const PERMA_DELETEING_MEDIA = 'PERMA_DELETEING_MEDIA';
export const PERMA_DELETE_MEDIA = 'PERMA_DELETE_MEDIA';
export const PERMA_DELETE_MEDIA_ERROR = 'PERMA_DELETE_MEDIA_ERROR';

export const RESTORE_MEDIA_LIST_LOADING = 'RESTORE_MEDIA_LIST_LOADING';
export const RESTORE_MEDIA_LIST = 'RESTORE_MEDIA_LIST';
export const RESTORE_MEDIA_LIST_ERROR = 'RESTORE_MEDIA_LIST_ERROR';
