import {
  SERVICE_MENU_TYPE_LIST_LOADING,
  GET_SERVICE_MENU_TYPE_LIST,
  GET_SERVICE_MENU_TYPE_LIST_ERROR,
  GET_SERVICE_MENU_TYPE_BY_ID_LOADING,
  GET_SERVICE_MENU_TYPE_BY_ID_SUCCESS,
  GET_SERVICE_MENU_TYPE_BY_ID_ERROR,
  DELETEING_BULK_SERVICE_MENU_TYPE,
  DELETE_BULK_SERVICE_MENU_TYPE,
  DELETE_BULK_SERVICE_MENU_TYPE_ERROR,
  DELETED_BULK_SERVICE_MENU_TYPE_LIST_LOADING,
  GET_DELETED_BULK_SERVICE_MENU_TYPE_LIST,
  GET_DELETED_BULK_SERVICE_MENU_TYPE_LIST_ERROR,
  RESTORE_BULK_SERVICE_MENU_TYPE_LIST_LOADING,
  RESTORE_BULK_SERVICE_MENU_TYPE_LIST,
  RESTORE_BULK_SERVICE_MENU_TYPE_LIST_ERROR,
  PERMA_DELETE_BULK_SERVICE_MENU_TYPE,
  PERMA_DELETE_BULK_SERVICE_MENU_TYPE_ERROR,
  PERMA_DELETEING_BULK_SERVICE_MENU_TYPE,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../../urls";
import { message } from "antd";

export const getList = (tokenData) => async (dispatch) => {
  dispatch({
    type: SERVICE_MENU_TYPE_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/hotel/servicemenu/type/active`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_SERVICE_MENU_TYPE_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_SERVICE_MENU_TYPE_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const getEntityById = (tokenData, id) => async (dispatch) => {
  dispatch({
    type: GET_SERVICE_MENU_TYPE_BY_ID_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/hotel/servicemenu/type/active/${id}`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_SERVICE_MENU_TYPE_BY_ID_SUCCESS,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_SERVICE_MENU_TYPE_BY_ID_ERROR,
        payload: error.response,
      });
    });
};


export const getDeletedList = (tokenData) => async (dispatch) => {
  dispatch({
    type: DELETED_BULK_SERVICE_MENU_TYPE_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/hotel/servicemenu/type/deleted`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_DELETED_BULK_SERVICE_MENU_TYPE_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_DELETED_BULK_SERVICE_MENU_TYPE_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const deleteEntityById = (idList) => async (dispatch) => {
  dispatch({
    type: DELETEING_BULK_SERVICE_MENU_TYPE,
  });
  let bulk = { bulk: idList }
  await axios
    .post(`${BASE_URL}api/v1/admin/hotel/servicemenu/type/bulk/delete`, bulk, {
      headers: {
        'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: DELETE_BULK_SERVICE_MENU_TYPE,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: DELETE_BULK_SERVICE_MENU_TYPE_ERROR,
        payload: error.response,
      });
    });
};

export const permanentDeleteEntity = (idList) => async (dispatch) => {
  dispatch({
    type: PERMA_DELETEING_BULK_SERVICE_MENU_TYPE,
  });
  let bulk = { bulk: idList }
  await axios
    .post(`${BASE_URL}api/v1/admin/hotel/servicemenu/type/bulk/perma-delete`, bulk, {
      headers: {
        'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: PERMA_DELETE_BULK_SERVICE_MENU_TYPE,
        payload: response,
      })
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: PERMA_DELETE_BULK_SERVICE_MENU_TYPE_ERROR,
        payload: error.response,
      })
      message.error(error.response.data.message);
    });
};

export const restoretDeleteEntity = (idList, history) => async (dispatch) => {
  dispatch({
    type: RESTORE_BULK_SERVICE_MENU_TYPE_LIST_LOADING,
  });
  let bulk = { bulk: idList }
  await axios
    .post(`${BASE_URL}api/v1/admin/hotel/servicemenu/type/bulk/restore`, bulk, {
      headers: {
        'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: RESTORE_BULK_SERVICE_MENU_TYPE_LIST,
        payload: response,
      })
      history.push("/hotel/menu-types")
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: RESTORE_BULK_SERVICE_MENU_TYPE_LIST_ERROR,
        payload: error.response,
      })
      message.error(error.response.data.message);
    });
};


// export const sortEntityById = (idList) => async (dispatch) => {
//   dispatch({
//     type: SORTING_PACKAGE,
//   });

//   let webKitFormData = new FormData();
//   if (idList.item_array) {
//     for (let i = 0; i < idList.item_array.length; i++) {
//       webKitFormData.append("package_array[]", idList.item_array[i]);
//     }
//   }
//   ["item_array"].forEach((e) => delete idList[e]);
//   Object.keys(idList).forEach((item, i) => {
//     webKitFormData.append(item, idList[item]);
//   });
//   await axios
//     .post(`${BASE_URL}api/v1/admin/travel/package/order`, webKitFormData, {
//       headers: {
//         Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
//       },
//     })
//     .then(function (response) {
//       dispatch({
//         type: SORT_PACKAGE,
//         payload: response,
//       });
//       message.success(response.data.message);
//     })
//     .catch(function (error) {
//       dispatch({
//         type: PACKAGE_SORT_ERROR,
//         payload: error.response,
//       });
//       message.error(error.response.data.message);
//     });
// };
