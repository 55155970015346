import React from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import DashboardUpdate from "../../../Components/Layouts/DashboardUpdate";
import AmenitiesForm from "../../../Components/Forms/Hotel/AmenitiesForm";
import { createEntity, updateEntityInfo, setUpdatedRecentlyToFalse } from "../../../Redux/Hotel/AmenitiesForm/actions";
import { getEntityById } from "../../../Redux/Hotel/Amenities/actions";

function Update({useAuth, getEntityById, createEntity, updateEntityInfo, setUpdatedRecentlyToFalse, data, loginData, selectedForm}) {
  let params = useParams();
  return (
    <DashboardUpdate useAuth={useAuth}>
        <AmenitiesForm
          isUpdateForm
          {...{params, getEntityById, createEntity, updateEntityInfo, setUpdatedRecentlyToFalse, data, loginData, selectedForm}}
        />
    </DashboardUpdate>
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.amenities,
    loginData: state.loginData,
    selectedForm: state.amenitiesForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEntityById: (tokenData, id) => dispatch(getEntityById(tokenData, id)),
    createEntity: (formData, tokenData) => dispatch(createEntity(formData, tokenData)),
    updateEntityInfo: (formData, tokenData, id) => dispatch(updateEntityInfo(formData, tokenData, id)),
    setUpdatedRecentlyToFalse: () => dispatch(setUpdatedRecentlyToFalse()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Update);
