import {
  CREATING_ALBUM,
  ERROR_ALBUM_CREATE,
  UPDATING_ALBUM,
  ALBUM_CREATED,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE,
  ALBUM_UPDATED,
  ERROR_ALBUM_UPDATE,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  loadedRecently: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATING_ALBUM:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case ALBUM_CREATED:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case ERROR_ALBUM_CREATE:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

    case UPDATING_ALBUM:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ALBUM_UPDATED:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };
    case ERROR_ALBUM_UPDATE:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case SET_UPDATED_RECENTLY_TO_FALSE:
      return {
        ...state,
        loadedRecently: false
      }
    case UPDATED_RECENTLY:
      return {
        ...state,
        loadedRecently: true
      }
    default:
      return state;
  }
};

export default reducer;
