import React, { useState } from "react";

import InputField, {PasswordInputField, EmailInputField, SwitchInput} from "../../Components/Core/InputField"
import { RoundedSubmitButton } from "../../Components/Core/Button"

import Logo from "../../assets/images/wood-apple-logo.png";
import { useContext } from "react";
import GlobalContext from "../../utils/GlobalContext";
import { BASE_URL } from "../../Redux/urls";

const LoginForm = ({ loginClicked, loginData }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [userCredentials, setUserCredentials] = useState({
    email: "",
    password: "",
  });
  const [remember_me, setRememberMe] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleRemberMe = () => {
    setRememberMe(!remember_me);
  };
  const globalData = useContext(GlobalContext)
  return (
    <div className="login-module">
      <div className="login-module-header">
        <a href="#" className="logo">
          {/* <img src={globalData?.logo_light?BASE_URL+globalData?.logo_light:null} alt={globalData?.company_name?globalData?.company_name:null} /> */}
          <img src={Logo} alt="Hotel Wood Apple" />
        </a>
      </div>
      <div className="login-module-body">
        <form>

          
          <div className="form-group">
            <EmailInputField
              value={userCredentials.email}
              onChange={(e) =>
                setUserCredentials({
                  ...userCredentials,
                  email: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <PasswordInputField
              value={userCredentials.password}
              onChange={(e) =>
                setUserCredentials({
                  ...userCredentials,
                  password: e.target.value,
                })
              }
            />
            <div className="forget-password text-right">
              <a href="/forget-password">Forgot Password?</a>
            </div>
          </div>
          <div className="from-group">
            <SwitchInput
              checked={remember_me}
              onClick={toggleRemberMe}
            />
          </div>       
        </form>
        <RoundedSubmitButton
          loading={loginData.loading}
          disabled={loginData.loading}
          onClick={() => loginClicked(userCredentials, remember_me)}
        >
          Log In
        </RoundedSubmitButton>
        {loginData.error && (
          <div>
            <br />
            <div className="alert alert-danger" role="alert">
              {loginData.data.message}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default LoginForm
