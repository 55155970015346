import {
  CREATING_CATEGORY,
  UPDATING_CATEGORY,
  CATEGORY_CREATED,
  CATEGORY_UPDATED,
  ERROR_CATEGORY_CREATE,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const setUpdatedRecentlyToFalse = () => async (dispatch) => {
  dispatch({
    type: SET_UPDATED_RECENTLY_TO_FALSE,
  });
};

export const createEntity =
  (formData, tokenData, exitNow, history) => async (dispatch) => {
    dispatch({
      type: CREATING_CATEGORY,
    });

    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );

    let webKitFormData = new FormData();

    if (data.banners) {
      let bannerImage = data.banners.map((item) => item.banner_image);
      let bannerCaption = data.banners.map((item) => item.banner_caption);
      let bannerAlt = data.banners.map((item) => item.banner_alt);
      for (let i = 0; i < bannerImage.length; i++) {
        webKitFormData.append("banners[].banner_image", bannerImage[i]);
      }
      for (let i = 0; i < bannerCaption.length; i++) {
        webKitFormData.append("banners[].banner_caption", bannerCaption[i]);
      }
      for (let i = 0; i < bannerAlt.length; i++) {
        webKitFormData.append("banners[].banner_alt", bannerAlt[i]);
      }
    }
    ["banners"].forEach((e) => delete data[e]);

    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });

    await axios
      .post(`${BASE_URL}api/v1/admin/category/add`, webKitFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: CATEGORY_CREATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        if (exitNow) {
          history.push("/categories");
          message.success(response.data.message);
        }
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_CATEGORY_CREATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };

export const updateEntityInfo =
  (formData, tokenData, id) => async (dispatch) => {
    dispatch({
      type: UPDATING_CATEGORY,
    });

    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );

    let webKitFormData = new FormData();
    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });
    webKitFormData.append("_method", "put");

    await axios
      .post(`${BASE_URL}api/v1/admin/category/edit/${id}`, webKitFormData, {
        headers: {
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: CATEGORY_UPDATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        message.success(response.data.message);
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_CATEGORY_CREATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };
