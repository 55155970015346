import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import DashboardAdd from "../../../Components/Layouts/DashboardAdd";
import ServiceMenuTypeForm from "../../../Components/Forms/Hotel/ServiceMenuTypeForm";
import { createEntity, updateEntityInfo, setUpdatedRecentlyToFalse } from "../../../Redux/Hotel/ServiceMenuTypeForm/actions";
import { getEntityById } from "../../../Redux/Hotel/ServiceMenuType/actions";


function ServiceMenuTypeUpdate({ useAuth, getEntityById, createEntity, updateEntityInfo, setUpdatedRecentlyToFalse, data, loginData, selectedForm }) {  
  let params = useParams();
  return (
    <DashboardAdd useAuth={useAuth}>
        <ServiceMenuTypeForm
          isUpdateForm
          {...{params, getEntityById, createEntity, updateEntityInfo, setUpdatedRecentlyToFalse, data, loginData, selectedForm}}
        />
    </DashboardAdd>
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.serviceMenuType,
    selectedForm: state.serviceMenuTypeForm,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEntityById: (tokenData, id) => dispatch(getEntityById(tokenData, id)),
    createEntity: (formData, tokenData, exitAfter, history) => dispatch(createEntity(formData, tokenData, exitAfter, history)),
    updateEntityInfo: (formData, tokenData, id) => dispatch(updateEntityInfo(formData, tokenData, id)),
    setUpdatedRecentlyToFalse: () => dispatch(setUpdatedRecentlyToFalse()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceMenuTypeUpdate);
