import React from "react";
import { connect } from "react-redux";
import DashboardList from "../../../Components/Layouts/DashboardList";
import { getList, deleteEntityById } from "../../../Redux/Hotel/Amenities/actions";

function List({ useAuth, getList, list, onDelete }) {
 
  const tableConfig = {
    title: "Amenities",
    filterOptionTitle: "User Type",
    applyFilter: false,
    tableColumns: [
      {name: "Title", selector: 'facility_title', width: '70%'},
      {name: "Updated At", selector: 'updated_at', width: '15%'},
      {name: "ID", selector: 'id', width: '2%'}
    ],
    filterByColumn: 'type',
    searchByColumn: 'name',
    addPath: '/hotel/amenity/add',
    updatePath: "/hotel/amenity/update",
    noDataMessage: "No amenity exists",
    deleteBtnText: 'Delete',
    updateLinkColumn: 'facility_title',
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  }
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.amenities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),    
    onDelete: (idList) => dispatch(deleteEntityById(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
