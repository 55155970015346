import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import RouteAuth from "./Components/RouteAuth";
import Dashboard from "./Containers/Dashboard";
import Login from "./Containers/Login";

import UserAdd from "./Containers/User/Add";
import UserUpdate from "./Containers/User/Update";
import UserList from "./Containers/User/List";

import SettingAdd from "./Containers/Settings/Add";
import SettingUpdate from "./Containers/Settings/Update";
import SettingsList from "./Containers/Settings/List";

import AuthorAdd from "./Containers/Author/Add";
import AuthorUpdate from "./Containers/Author/Update";
import AuthorList from "./Containers/Author/List";
import DeletedAuthor from "./Containers/Author/DeletedList";

import PageAdd from "./Containers/Page/Add";
import PageUpdate from "./Containers/Page/Update";
import PageList from "./Containers/Page/List";
import DeletedPage from "./Containers/Page/DeletedList";

import TeamAdd from "./Containers/Team/Add";
import TeamUpdate from "./Containers/Team/Update";
import TeamList from "./Containers/Team/List";
import DeletedTeam from "./Containers/Team/DeletedList";

import TestimonialAdd from "./Containers/Testimonial/Add";
import TestimonialUpdate from "./Containers/Testimonial/Update";
import TestimonialList from "./Containers/Testimonial/List";
import DeletedTestimonial from "./Containers/Testimonial/DeletedList";

import CommentAdd from "./Containers/Comment/Add";
import CommentUpdate from "./Containers/Comment/Update";
import CommentList from "./Containers/Comment/List";
import DeletedComment from "./Containers/Comment/DeletedList";

import FaqsAdd from "./Containers/Faqs/Add";
import FaqsUpdate from "./Containers/Faqs/Update";
import FaqsList from "./Containers/Faqs/List";
import DeletedFaqs from "./Containers/Faqs/DeletedList";

import CategoryType from "./Containers/Category/CategoryType";
import CategoryTypeAdd from "./Containers/Category/CategoryTypeAdd";
import CategoryTypeUpdate from "./Containers/Category/CategoryTypeUpdate";
import DeletedCategoryType from "./Containers/Category/DeletedCategoryType";
import CategoryAdd from "./Containers/Category/Add";
import CategoryUpdate from "./Containers/Category/Update";
import CategoryList from "./Containers/Category/List";
import DeletedCategory from "./Containers/Category/DeletedList";

import BlogAdd from "./Containers/Blog/Add";
import BlogUpdate from "./Containers/Blog/Update";
import BlogList from "./Containers/Blog/List";
import DeletedBlog from "./Containers/Blog/DeletedList";

//HOTELS
//packages
import PackageAdd from "./Containers/Hotel/Package/Add";
import PackageUpdate from "./Containers/Hotel/Package/Update";
import PackageList from "./Containers/Hotel/Package/List";
import DeletedPackage from "./Containers/Hotel/Package/DeletedList";
//Room
import RoomAdd from "./Containers/Hotel/Room/Add";
import RoomUpdate from "./Containers/Hotel/Room/Update";
import RoomList from "./Containers/Hotel/Room/List";
import DeletedRoom from "./Containers/Hotel/Room/DeletedList";

import AmenitiesAdd from "./Containers/Hotel/Amenities/Add";
import AmenitiesUpdate from "./Containers/Hotel/Amenities/Update";
import AmenitiesList from "./Containers/Hotel/Amenities/List";

import ServiceMenuTypeAdd from "./Containers/Hotel/ServiceMenu/TypeAdd";
import ServiceMenuTypeUpdate from "./Containers/Hotel/ServiceMenu/TypeUpdate";
import ServiceMenuTypeList from "./Containers/Hotel/ServiceMenu/Type";

import ServiceMenuAdd from "./Containers/Hotel/ServiceMenu/Add";
import ServiceMenuUpdate from "./Containers/Hotel/ServiceMenu/Update";
import ServiceMenuList from "./Containers/Hotel/ServiceMenu/List";

//menu
import MenuAdd from "./Containers/Menu/Add";
import MenuUpdate from "./Containers/Menu/Update";
import MenuOrder from "./Containers/Menu/Order";
import MenuList from "./Containers/Menu/List";
import DeletedMenu from "./Containers/Menu/DeletedList";

//menuType
import MenuTypeAdd from "./Containers/Menu/MenuTypeAdd";
import MenuTypeUpdate from "./Containers/Menu/MenuTypeUpdate";
import MenuTypeList from "./Containers/Menu/MenuType";
import DeletedMenuType from "./Containers/Menu/DeletedMenuType";

//mediaTypes
import AlbumAdd from "./Containers/Album/Add";
import AlbumUpdate from "./Containers/Album/Update";
import AlbumList from "./Containers/Album/List";
import DeletedAlbum from "./Containers/Album/DeletedList";

//media
import MediaAdd from "./Containers/Media/Add";
import MediaUpdate from "./Containers/Media/Update";
import MediaList from "./Containers/Media/List";
import DeletedMedia from "./Containers/Media/DeletedList";

//Booking
import BookingAdd from "./Containers/Booking/Add";
import BookingUpdate from "./Containers/Booking/Update";
import BookingList from "./Containers/Booking/List";
import InquiryList from "./Containers/Booking/InquiryList";

//Payment
import PaymentAdd from "./Containers/Payment/Add";
import PaymentUpdate from "./Containers/Payment/Update";
import PaymentList from "./Containers/Payment/List";

// Redux
import { Provider } from "react-redux";
import store from "./Redux/store";

// Bootstrap, Popper and Styles
import "bootstrap";
import "@popperjs/core";
// import "bootstrap/dist/css/bootstrap.css";

// BUGGY VERSION --> import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/bootstrap-icons/font/bootstrap-icons.css";

import "./assets/scss/main.scss";
import "./index.css";
import AllLogs from "./Containers/AllLogs";

const pages_and_routes = {
  protected_pages: [
    { path: "/dashboard", Page: Dashboard },
    { path: "/all-logs", Page: AllLogs },

    { path: "/settings", Page: SettingsList },
    { path: "/setting/add", Page: SettingAdd },
    { path: "/setting/update/:id", Page: SettingUpdate },

    { path: "/user/add", Page: UserAdd },
    { path: "/user/update/:id", Page: UserUpdate },
    { path: "/users", Page: UserList },

    { path: "/category-types", Page: CategoryType },
    { path: "/category-type/add", Page: CategoryTypeAdd },
    { path: "/category-type/update/:id", Page: CategoryTypeUpdate },
    { path: "/category-type/deleted", Page: DeletedCategoryType },
    { path: "/category/add", Page: CategoryAdd },
    { path: "/category/update/:id", Page: CategoryUpdate },
    { path: "/categories", Page: CategoryList },
    { path: "/category/deleted", Page: DeletedCategory },

    { path: "/articles", Page: PageList },
    { path: "/article/update/:id", Page: PageUpdate },
    { path: "/article/add", Page: PageAdd },
    { path: "/article/deleted", Page: DeletedPage },

    { path: "/manage-menu", Page: MenuTypeList },
    { path: "/menu-type/add", Page: MenuTypeAdd },
    { path: "/menu-type/update/:id", Page: MenuTypeUpdate },
    { path: "/menu-type/deleted", Page: DeletedMenuType },

    { path: "/menus", Page: MenuList },
    { path: "/menu/add", Page: MenuAdd },
    { path: "/menu/order", Page: MenuOrder },
    { path: "/menu/update/:id", Page: MenuUpdate },
    { path: "/menu/deleted", Page: DeletedMenu },

    { path: "/teams", Page: TeamList },
    { path: "/team/update/:id", Page: TeamUpdate },
    { path: "/team/add", Page: TeamAdd },
    { path: "/team/deleted", Page: DeletedTeam },

    { path: "/testimonials", Page: TestimonialList },
    { path: "/testimonial/update/:id", Page: TestimonialUpdate },
    { path: "/testimonial/add", Page: TestimonialAdd },
    { path: "/testimonial/deleted", Page: DeletedTestimonial },

    { path: "/faqs", Page: FaqsList },
    { path: "/faq/update/:id", Page: FaqsUpdate },
    { path: "/faq/add", Page: FaqsAdd },
    { path: "/faq/deleted", Page: DeletedFaqs },

    { path: "/blog/author/add", Page: AuthorAdd },
    { path: "/blog/author/update/:id", Page: AuthorUpdate },
    { path: "/blog/authors", Page: AuthorList },
    { path: "/blog/author/deleted", Page: DeletedAuthor },

    { path: "/blog/add", Page: BlogAdd },
    { path: "/blog/update/:id", Page: BlogUpdate },
    { path: "/blogs", Page: BlogList },
    { path: "/blog/deleted", Page: DeletedBlog },

    { path: "/blog/comment/add", Page: CommentAdd },
    { path: "/blog/comment/update/:id", Page: CommentUpdate },
    { path: "/blog/comments", Page: CommentList },
    { path: "/blog/comment/deleted", Page: DeletedComment },

    { path: "/hotel/package/add", Page: PackageAdd },
    { path: "/hotel/package/update/:id", Page: PackageUpdate },
    { path: "/hotel/packages", Page: PackageList },
    { path: "/hotel/package/deleted", Page: DeletedPackage },

    { path: "/hotel/room/add", Page: RoomAdd },
    { path: "/hotel/room/update/:id", Page: RoomUpdate },
    { path: "/hotel/rooms", Page: RoomList },
    { path: "/hotel/room/deleted", Page: DeletedRoom },

    { path: "/hotel/amenity/update/:id", Page: AmenitiesUpdate },
    { path: "/hotel/amenities", Page: AmenitiesList },
    { path: "/hotel/amenity/add", Page: AmenitiesAdd },

    { path: "/hotel/service-menu-type/update/:id", Page: ServiceMenuTypeUpdate },
    { path: "/hotel/service-menu-types", Page: ServiceMenuTypeList },
    { path: "/hotel/service-menu-type/add", Page: ServiceMenuTypeAdd },

    { path: "/hotel/service-menu/update/:id", Page: ServiceMenuUpdate },
    { path: "/hotel/all-service-menu", Page: ServiceMenuList },
    { path: "/hotel/service-menu/add", Page: ServiceMenuAdd },

    { path: "/album/add", Page: AlbumAdd },
    { path: "/album/update/:id", Page: AlbumUpdate },
    { path: "/albums", Page: AlbumList },
    { path: "/album/deleted", Page: DeletedAlbum },

    { path: "/media/add", Page: MediaAdd },
    { path: "/media/update/:id", Page: MediaUpdate },
    { path: "/allmedia", Page: MediaList },
    { path: "/media/deleted", Page: DeletedMedia },
    
    { path: "/booking/add", Page: BookingAdd },
    { path: "/booking/update/:id", Page: BookingUpdate },
    { path: "/bookings", Page: BookingList },
    { path: "/inquiries", Page: InquiryList },

    { path: "/payment/add", Page: PaymentAdd },
    { path: "/payment/update/:id", Page: PaymentUpdate },
    { path: "/payments", Page: PaymentList },

  ],
  public_pages: [
    // { path: "/home", Page: PublicHome },
    // { path: "/about-us", Page: PublicAboutUs },
  ],
  AUTH_ENTRY_PAGE: { path: "/login", Page: Login },
  ROOT_PAGE: { path: "/", Page: Login },
};

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <RouteAuth data={pages_and_routes} />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
