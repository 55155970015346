import React from "react";
import Toolbar from "../../Components/Toolbar";
import Sidebar from "../../Components/Sidebar";
import NavBarDashboard from "../../Components/NavBarDashboard";
import Footer from "../../Components/Footer";

import { BASE_URL } from "../../Redux/urls";
import axios from "axios";
import GlobalContext from "../../utils/GlobalContext";

export default function DashboardLayout({ useAuth, children }) {
  const [sidebar_active, setSidebarActive] = React.useState(false);
  const [globalData, setGlobalData] = React.useState();
  React.useEffect(async () => {
    if (!globalData) {
      const res = await axios.get(`${BASE_URL}api/v1/admin/global`);
      const data = await res.data;
      setGlobalData(data.data);
    }
  }, [globalData]);

  return (
    <GlobalContext.Provider value={globalData}>
      <div
        className={`contain-wrapper ${sidebar_active ? "sidebar-active" : ""}`}
      >
        <Toolbar
          toggleSidebar={() => setSidebarActive(!sidebar_active)}
          sidebarOpen={sidebar_active}
          useAuth={useAuth}
        />
        <Sidebar />
        <div className="page-wrapper">
          <NavBarDashboard />
          {children}
          <Footer />
        </div>
      </div>
    </GlobalContext.Provider>
  );
}
