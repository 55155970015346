export const AMENITIES_LIST_LOADING = "AMENITIES_LIST_LOADING";
export const GET_AMENITIES_LIST = "GET_AMENITIES_LIST";
export const GET_AMENITIES_LIST_ERROR = "GET_AMENITIES_LIST_ERROR";
export const GET_AMENITIES_BY_ID_LOADING = "GET_AMENITIES_BY_ID_LOADING";
export const GET_AMENITIES_BY_ID_SUCCESS = "GET_AMENITIES_BY_ID_SUCCESS";
export const GET_AMENITIES_BY_ID_ERROR = "GET_AMENITIES_BY_ID_ERROR";

export const DELETEING_AMENITIES = "DELETEING_AMENITIES";
export const DELETE_AMENITIES = "DELETE_AMENITIES";
export const DELETE_AMENITIES_ERROR = "DELETE_AMENITIES_ERROR" 
