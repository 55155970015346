export const SERVICE_MENU_TYPE_LIST_LOADING = "SERVICE_MENU_TYPE_LIST_LOADING";
export const GET_SERVICE_MENU_TYPE_LIST = "GET_SERVICE_MENU_TYPE_LIST";
export const GET_SERVICE_MENU_TYPE_LIST_ERROR = "GET_SERVICE_MENU_TYPE_LIST_ERROR";

export const GET_SERVICE_MENU_TYPE_BY_ID_LOADING = "GET_SERVICE_MENU_TYPE_BY_ID_LOADING";
export const GET_SERVICE_MENU_TYPE_BY_ID_SUCCESS = "GET_SERVICE_MENU_TYPE_BY_ID_SUCCESS";
export const GET_SERVICE_MENU_TYPE_BY_ID_ERROR = "GET_SERVICE_MENU_TYPE_BY_ID_ERROR";

export const DELETEING_BULK_SERVICE_MENU_TYPE = "DELETEING_BULK_SERVICE_MENU_TYPE";
export const DELETE_BULK_SERVICE_MENU_TYPE = "DELETE_BULK_SERVICE_MENU_TYPE";
export const DELETE_BULK_SERVICE_MENU_TYPE_ERROR = "DELETE_BULK_SERVICE_MENU_TYPE_ERROR" 

export const DELETED_BULK_SERVICE_MENU_TYPE_LIST_LOADING = "DELETED_BULK_SERVICE_MENU_TYPE_LIST_LOADING";
export const GET_DELETED_BULK_SERVICE_MENU_TYPE_LIST = "GET_DELETED_BULK_SERVICE_MENU_TYPE_LIST";
export const GET_DELETED_BULK_SERVICE_MENU_TYPE_LIST_ERROR = "GET_DELETED_BULK_SERVICE_MENU_TYPE_LIST_ERROR";

export const RESTORE_BULK_SERVICE_MENU_TYPE_LIST_LOADING = "RESTORE_BULK_SERVICE_MENU_TYPE_LIST_LOADING";
export const RESTORE_BULK_SERVICE_MENU_TYPE_LIST = "RESTORE_BULK_SERVICE_MENU_TYPE_LIST";
export const RESTORE_BULK_SERVICE_MENU_TYPE_LIST_ERROR = "RESTORE_BULK_SERVICE_MENU_TYPE_LIST_ERROR";

export const PERMA_DELETEING_BULK_SERVICE_MENU_TYPE = "PERMA_DELETEING_BULK_SERVICE_MENU_TYPE";
export const PERMA_DELETE_BULK_SERVICE_MENU_TYPE = "PERMA_DELETE_BULK_SERVICE_MENU_TYPE";
export const PERMA_DELETE_BULK_SERVICE_MENU_TYPE_ERROR = "PERMA_DELETE_BULK_SERVICE_MENU_TYPE_ERROR" 
