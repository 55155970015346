import {
  AMENITIES_LIST_LOADING,
  GET_AMENITIES_LIST,
  GET_AMENITIES_LIST_ERROR,
  GET_AMENITIES_BY_ID_LOADING,
  GET_AMENITIES_BY_ID_SUCCESS,
  GET_AMENITIES_BY_ID_ERROR,
  DELETEING_AMENITIES,
  DELETE_AMENITIES,
  DELETE_AMENITIES_ERROR,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../../urls";
import { message } from "antd";

export const getList = (tokenData) => async (dispatch) => {
  dispatch({
    type: AMENITIES_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/hotel/facility/all`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_AMENITIES_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_AMENITIES_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const getEntityById = (tokenData, id) => async (dispatch) => {
  dispatch({
    type: GET_AMENITIES_BY_ID_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/hotel/facility/single/${id}`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_AMENITIES_BY_ID_SUCCESS,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_AMENITIES_BY_ID_ERROR,
        payload: error.response,
      });
    });
};



export const deleteEntityById = (idList) => async (dispatch) => {
  dispatch({
    type: DELETEING_AMENITIES,
  });
  let bulk = {bulk: idList}
  await axios
    .post(`${BASE_URL}api/v1/admin/hotel/facility/bulk/delete`, bulk, {
      headers: {
        'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: DELETE_AMENITIES,
        payload: response,
      })
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: DELETE_AMENITIES_ERROR,
        payload: error.response,
      })      
      message.error(error.response.data.message);
    });
};

