import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { RoundedSubmitButton } from "./Core/Button";
import { message } from "antd";

const DashboardActionBar = ({ actionBarOptions }) => {
  let history = useHistory();
  useEffect(() => {
    const actionBar = document.querySelector(".action-bar");
    if (actionBar) {
      const actionBarOffsetTop = actionBar.offsetTop;
      actionBar.parentElement.style.minHeight = actionBar.offsetHeight + "px";
      window.onscroll = () => {
        if (window.scrollY > actionBarOffsetTop) {
          actionBar.parentElement.classList.add("fixed-position");
        } else {
          actionBar.parentElement.classList.remove("fixed-position");
        }
      };
    }
  }, []);
  if (
    actionBarOptions.selectedForm?.loadedRecently &&
    actionBarOptions.isAddForm
  ) {
    actionBarOptions.clearForm();
  }
  if (actionBarOptions.selectedForm?.loadedRecently) {
    setTimeout(() => {
      actionBarOptions.setUpdatedRecentlyToFalse();
    }, actionBarOptions.taskCompleteMessageDisplayTime);
  }

  return (
    <div className="common-module mb-2">
      
        <div className="action-bar">
          <div className="title">
            {actionBarOptions.isAddForm && (
              <h1>{actionBarOptions.titleToAdd}</h1>
            )}
            {!actionBarOptions.loading  && actionBarOptions.isUpdateForm &&
              actionBarOptions.titleToUpdate && (
                <>
                  <h1>{actionBarOptions.titleToUpdate}</h1>
                 {actionBarOptions.pageUrl && <a
                    href={
                      process.env.REACT_APP_FRONT_URL + actionBarOptions.pageUrl
                    }
                    className="external-link"
                    target="_blank"
                  >
                    {'/'+actionBarOptions.pageUrl}
                  </a> }
                </>
              )}
          </div>

          <div className="action-slot">
            <ul>
              {/* {actionBarOptions.loading && (
              <li>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              </li>
            )} */}

              {actionBarOptions.steps && (
                <li>
                  {actionBarOptions.current > 0 && (
                    <button
                      className="btn btn-warning"
                      onClick={actionBarOptions.prev}
                      id="add_new"
                    >
                      <i className="bi bi-arrow-left-short"></i> Previous
                    </button>
                  )}
                </li>
              )}
              {actionBarOptions.steps && (
                <li>
                  {actionBarOptions.current <
                    actionBarOptions.totalSteps - 1 && (
                    <button
                      className="btn btn-warning"
                      onClick={actionBarOptions.next}
                      id="add_new"
                    >
                      Next{" "}
                      <i
                        className="bi bi-arrow-right-short"
                        style={{ marginRight: 0 }}
                      ></i>
                    </button>
                  )}
                </li>
              )}
              {actionBarOptions.steps
                ? actionBarOptions.isAddForm &&
                  actionBarOptions.current ===
                    actionBarOptions.totalSteps - 1 && (
                    <li>
                      <RoundedSubmitButton
                        disabled={actionBarOptions.loading}
                        htmlType="submit"
                        className="btn btn-primary"
                        onClick={actionBarOptions.addNewEntryAndExistToList}
                        id="add_new"
                      >
                        <i className="bi bi-save2"></i>Save
                      </RoundedSubmitButton>
                    </li>
                  )
                : actionBarOptions.isAddForm && (
                    <li>
                      <RoundedSubmitButton
                        disabled={actionBarOptions.loading}
                        htmlType="submit"
                        className="btn btn-primary"
                        onClick={actionBarOptions.addNewEntryAndExistToList}
                        id="add_new"
                      >
                        <i className="bi bi-save2"></i>Save
                      </RoundedSubmitButton>
                    </li>
                  )}

              {actionBarOptions.steps
                ? actionBarOptions.isAddForm &&
                  actionBarOptions.current ===
                    actionBarOptions.totalSteps - 1 && (
                    <li>
                      <RoundedSubmitButton
                        disabled={actionBarOptions.loading}
                        className="btn btn-success"
                        id="add_new"
                        onClick={actionBarOptions.addNewEntry}
                      >
                        <i className="bi bi-plus"></i>
                        {actionBarOptions.addButtonLabel}
                      </RoundedSubmitButton>
                    </li>
                  )
                : actionBarOptions.isAddForm && (
                    <li>
                      <RoundedSubmitButton
                        disabled={actionBarOptions.loading}
                        className="btn btn-success"
                        id="add_new"
                        onClick={actionBarOptions.addNewEntry}
                      >
                        <i className="bi bi-plus"></i>
                        {actionBarOptions.addButtonLabel}
                      </RoundedSubmitButton>
                    </li>
                  )}

              {/* {actionBarOptions.steps
                ? actionBarOptions.isUpdateForm &&
                  actionBarOptions.current ===
                    actionBarOptions.totalSteps - 1 && (
                    <li><RoundedSubmitButton
                      disabled={actionBarOptions.loading}
                      className="btn btn-primary"
                      onClick={actionBarOptions.updateExistingEntry}
                      id="add_new"
                    >
                      <i className="bi bi-save"></i>
                      {actionBarOptions.updateButtonLabel}
                    </RoundedSubmitButton></li>
                  )
                : actionBarOptions.isUpdateForm && (
                    <li><RoundedSubmitButton
                      disabled={actionBarOptions.loading}
                      className="btn btn-primary"
                      onClick={actionBarOptions.updateExistingEntry}
                      id="add_new"
                    >
                      <i className="bi bi-save"></i>
                      {actionBarOptions.updateButtonLabel}
                    </RoundedSubmitButton></li>
                  )} */}

              {actionBarOptions.isUpdateForm && (
                <li>
                  <RoundedSubmitButton
                    disabled={actionBarOptions.loading}
                    className="btn btn-primary"
                    htmlType="submit"
                    onClick={actionBarOptions.updateExistingEntry}
                    id="add_new"
                  >
                    <i className="bi bi-save"></i>
                    {actionBarOptions.updateButtonLabel}
                  </RoundedSubmitButton>
                </li>
              )}

              <li>
                <span
                  className="btn btn-danger"
                  id="add_new"
                  onClick={() =>
                    history.push(`/${actionBarOptions.discardLink}`)
                  }
                >
                  <i className="bi bi-x"></i>Discard
                </span>
              </li>
            </ul>
          </div>
          {
            //actionBarOptions?.selectedForm?.loadedRecently &&  message.status(actionBarOptions?.selectedForm)
            actionBarOptions?.selectedForm?.data?.error &&
              actionBarOptions?.selectedForm?.data?.loadedRecently &&
              message.success(actionBarOptions?.selectedForm?.data?.message)
          }
        </div>
    </div>
  );
};

export default DashboardActionBar;
