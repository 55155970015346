import React, { useEffect, useState } from "react";
import { Form, Input, Col, Row, Switch, Select, Alert, Spin } from "antd";
import DashboardActionBar from "../../DashboardActionBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const { Option } = Select;
const ServiceMenuTypeForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
}) => {
  const tokenData = loginData.data;
  const [pageTitle, setPageTitle] = useState('')
  const history = useHistory();
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading = selectedForm.loading || data.loading

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id)
  }
  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById()
    }
  }, []);

  useEffect(() => {
    const alert = document.querySelector('.custom-alert')
    setTimeout(() => {
      alert.classList.add('fadeout')
    }, 5000)

  }, [selectedForm.error])

  const selectOptionsData = [
    "spa",
    "restaurant"
  ];

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (action === "add") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData
        );
      } else if (action === "addandexit") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData,
          true,
          history
        );
      } else if (action === "update") {
        updateEntityInfo(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData,
          params.id
        ).then(() => loadEntityById());
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
  };

  if (isUpdateForm) {
    setFieldsValue({
      title: data.dataById?.data.type_title,
      type_key: data.dataById?.data.type_key,
      description: data.dataById?.data.type_description,
      status: data.dataById?.data.status,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add Service Type",
    titleToUpdate: "Update Service Type",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update Service Type",
    taskCompleteAddMessage: "Service Type is added!",
    taskCompleteUpdateMessage: "Service Type is updated!",
    discardLink: "hotel/service-menu-types",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        {/* <DashboardActionBar {...{ actionBarOptions }} /> */}
        <div className="page-header">
          <div className="icon">
            <span
              className="btn-icon"
              onClick={() => history.push(`/${actionBarOptions.discardLink}`)}
            >
              <i className="bi-arrow-left-circle"></i>
            </span>

          </div>
          <div className="text">
            <h1>{isUpdateForm ? data.dataById?.data.type_title : pageTitle}</h1>
          </div>
        </div>
        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module bg-white">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Title is required",
                    }
                  ]}
                >
                  <Input onChange={(e) => isAddForm && setPageTitle(e.target.value)} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Type Key"
                  name="type_key"
                  rules={[
                    {
                      required: true,
                      message: "key is required",
                    },
                  ]}
                >
                  <Select>
                    {selectOptionsData?.map((itm) => {
                      return <Option key={itm}>{itm}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <div className="switch-group">
                  <Form.Item label="Status" valuePropName="checked" name="status">
                    <Switch
                      checkedChildren="Publish"
                      unCheckedChildren="Unpublish"
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            {isAddForm ? <button htmlType="submit"
              disabled={actionBarOptions.loading}
              className="btn btn-primary btn-save"
              onClick={actionBarOptions.addNewEntryAndExistToList}
              id="add_new"> <i className="bi bi-save2"></i>Save </button> : <button htmlType="submit"
                disabled={actionBarOptions.loading}
                className="btn btn-primary btn-save"
                onClick={actionBarOptions.updateExistingEntry}
                id="add_new"> <i className="bi bi-save2"></i>Update </button>}
          </div>

        </Spin>
      </Form>
    </>
  );
};

export default ServiceMenuTypeForm;
