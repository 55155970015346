import React, { useContext, createContext, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

export default function RouteAuth({ data }) {
  const { protected_pages, public_pages, AUTH_ENTRY_PAGE, ROOT_PAGE } = data;
  return (
    <ProvideAuth>
      <Router>
        <>
          <Switch>
            <Route exact path={ROOT_PAGE.path}>
              <ROOT_PAGE.Page useAuth={useAuth} />
            </Route>
            <Route path={AUTH_ENTRY_PAGE.path}>
              <AUTH_ENTRY_PAGE.Page useAuth={useAuth} />
            </Route>
            {protected_pages.map((ProtectedPage, idx) => (
              <PrivateRoute
                path={ProtectedPage.path}
                auth_path={AUTH_ENTRY_PAGE.path}
                key={idx}
              >
                <ProtectedPage.Page useAuth={useAuth} />
              </PrivateRoute>
            ))}
            {public_pages.map((PublicPage, idx) => (
              <Route path={PublicPage.path} key={idx}>
                <PublicPage.Page />
              </Route>
            ))}
          </Switch>
        </>
      </Router>
    </ProvideAuth>
  );
}

const updateLocalStorage = (action, data) => {
  if (action === "add") {
    Object.keys(data).map(item=>{
      localStorage.setItem(item, data[item]);
    })
  } else if(action === "delete") {
    localStorage.clear();
  }
}

const flamingoAuth = {
  isAuthenticated: false,
  signin(cb) {
    flamingoAuth.isAuthenticated = true;
    setTimeout(cb, 100); // async
  },
  signout(cb) {
    flamingoAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext();

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signin = (cb, loginData) => {
    updateLocalStorage('add', loginData.data)
    return flamingoAuth.signin(() => {
      setUser("User");
      cb();
    });
  };

  const signout = (cb) => {
    updateLocalStorage('delete')
    return flamingoAuth.signout(() => {
      setUser(null);
      cb();
    });
  };

  return {
    user,
    signin,
    signout,
  };
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, auth_path, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: auth_path,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
