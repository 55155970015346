import {
  MEDIA_LIST_LOADING,
  GET_MEDIA_LIST_ERROR,
  GET_MEDIA_LIST,
  GET_MEDIA_BY_ID_LOADING,
  GET_MEDIA_BY_ID_SUCCESS,
  GET_MEDIA_BY_ID_ERROR,
  DELETEING_MEDIA,
  DELETE_MEDIA,
  DELETE_MEDIA_ERROR,
  DELETED_MEDIA_LIST_LOADING,
  GET_DELETED_MEDIA_LIST,
  GET_DELETED_MEDIA_LIST_ERROR,
  PERMA_DELETEING_MEDIA,
  PERMA_DELETE_MEDIA,
  PERMA_DELETE_MEDIA_ERROR,
  RESTORE_MEDIA_LIST_LOADING,
  RESTORE_MEDIA_LIST,
  RESTORE_MEDIA_LIST_ERROR,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MEDIA_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_MEDIA_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETEING_MEDIA:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETED_MEDIA_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case PERMA_DELETEING_MEDIA:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case RESTORE_MEDIA_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_MEDIA_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_MEDIA_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };
    case GET_DELETED_MEDIA_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case DELETE_MEDIA:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case PERMA_DELETE_MEDIA:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case RESTORE_MEDIA_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case GET_MEDIA_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_MEDIA_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_DELETED_MEDIA_LIST_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case DELETE_MEDIA_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case PERMA_DELETE_MEDIA_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case RESTORE_MEDIA_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;