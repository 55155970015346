import {
  SERVICE_MENU_TYPE_LIST_LOADING,
  GET_SERVICE_MENU_TYPE_LIST_ERROR,
  GET_SERVICE_MENU_TYPE_LIST,
  GET_SERVICE_MENU_TYPE_BY_ID_LOADING,
  GET_SERVICE_MENU_TYPE_BY_ID_SUCCESS,
  GET_SERVICE_MENU_TYPE_BY_ID_ERROR,

  DELETEING_BULK_SERVICE_MENU_TYPE,
  DELETE_BULK_SERVICE_MENU_TYPE,
  DELETE_BULK_SERVICE_MENU_TYPE_ERROR,
  DELETED_BULK_SERVICE_MENU_TYPE_LIST_LOADING,
  GET_DELETED_BULK_SERVICE_MENU_TYPE_LIST,
  GET_DELETED_BULK_SERVICE_MENU_TYPE_LIST_ERROR,
  RESTORE_BULK_SERVICE_MENU_TYPE_LIST_LOADING,
  RESTORE_BULK_SERVICE_MENU_TYPE_LIST,
  RESTORE_BULK_SERVICE_MENU_TYPE_LIST_ERROR,
  PERMA_DELETEING_BULK_SERVICE_MENU_TYPE,
  PERMA_DELETE_BULK_SERVICE_MENU_TYPE,
  PERMA_DELETE_BULK_SERVICE_MENU_TYPE_ERROR,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  dataById: undefined,
  dataByKey: undefined
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SERVICE_MENU_TYPE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_SERVICE_MENU_TYPE_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETEING_BULK_SERVICE_MENU_TYPE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETED_BULK_SERVICE_MENU_TYPE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case RESTORE_BULK_SERVICE_MENU_TYPE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case PERMA_DELETEING_BULK_SERVICE_MENU_TYPE:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_SERVICE_MENU_TYPE_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_SERVICE_MENU_TYPE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };
    case DELETE_BULK_SERVICE_MENU_TYPE:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case GET_DELETED_BULK_SERVICE_MENU_TYPE_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case RESTORE_BULK_SERVICE_MENU_TYPE_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case PERMA_DELETE_BULK_SERVICE_MENU_TYPE:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case GET_SERVICE_MENU_TYPE_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_SERVICE_MENU_TYPE_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case DELETE_BULK_SERVICE_MENU_TYPE_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_DELETED_BULK_SERVICE_MENU_TYPE_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case RESTORE_BULK_SERVICE_MENU_TYPE_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case PERMA_DELETE_BULK_SERVICE_MENU_TYPE_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
