import {
  TRIPINFO_LIST_LOADING,
  GET_TRIPINFO_LIST_ERROR,
  GET_TRIPINFO_LIST,
  GET_TRIPINFO_BY_ID_LOADING,
  GET_TRIPINFO_BY_ID_SUCCESS,
  GET_TRIPINFO_BY_ID_ERROR,
  DELETEING_BLOG_TRIPINFO,
  DELETE_BLOG_TRIPINFO,
  DELETE_BLOG_TRIPINFO_ERROR,
  DELETED_BLOG_TRIPINFO_LIST_LOADING,
  GET_DELETED_BLOG_TRIPINFO_LIST,
  GET_DELETED_BLOG_TRIPINFO_LIST_ERROR,
  RESTORE_BLOG_TRIPINFO_LIST_LOADING,
  RESTORE_BLOG_TRIPINFO_LIST,
  RESTORE_BLOG_TRIPINFO_LIST_ERROR,
  PERMA_DELETEING_BLOG_TRIPINFO,
  PERMA_DELETE_BLOG_TRIPINFO,
  PERMA_DELETE_BLOG_TRIPINFO_ERROR,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRIPINFO_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_TRIPINFO_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETEING_BLOG_TRIPINFO:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETED_BLOG_TRIPINFO_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case RESTORE_BLOG_TRIPINFO_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case PERMA_DELETEING_BLOG_TRIPINFO:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_TRIPINFO_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_TRIPINFO_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };
    case DELETE_BLOG_TRIPINFO:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case GET_DELETED_BLOG_TRIPINFO_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case RESTORE_BLOG_TRIPINFO_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case PERMA_DELETE_BLOG_TRIPINFO:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case GET_TRIPINFO_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_TRIPINFO_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case DELETE_BLOG_TRIPINFO_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_DELETED_BLOG_TRIPINFO_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case RESTORE_BLOG_TRIPINFO_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case PERMA_DELETE_BLOG_TRIPINFO_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
