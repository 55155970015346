import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../Redux/urls";

const ImageField = ({ image, setImage, title, height, width }) => {
  const onChange = (e) => {
    e.preventDefault();
    setImage(e.target.files[0]);
  };
  let [imageToDisplay, setImageToDisplay] = useState();
  let reader = new FileReader();

  if (image && typeof image === "string") {
    if (image.indexOf("http") !== -1) {
      imageToDisplay = image;
    } else {
      imageToDisplay = BASE_URL + image;
    }
  } else if (image && typeof image === "object") {
    reader.onload = () => {
      imageToDisplay = reader.result;
    };
  }

  useEffect(() => {
    let reader = new FileReader();
    if (image && typeof image === "string") {
      setImageToDisplay(BASE_URL + image);
    } else if (image && typeof image === "object") {
      reader.readAsDataURL(image);
      reader.onload = () => {
        setImageToDisplay(reader.result);
      };
    }
  }, [image]);

  return (
    <>
      <h6>{title}</h6>
      <label
        className="thumbinal-image"
        id="thumbinal_image"
        style={{ height: height+'px', width: width+'px' }}
      >
        <figure style={{marginBottom:0}}>
          <img id="item-img-output" src={imageToDisplay} />
          <figcaption>
            <i className="bi bi-camera-fill"></i>
          </figcaption>
        </figure>
        <input
          type="file"
          image={image}
          className="item-img file center-block"
          id="thumbinalInput"
          hidden
          onChange={onChange}
        />
      </label>
    </>
  );
};

export default ImageField;
