import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Col,
  Row,
  Switch,
  Alert,
  Rate,
  DatePicker,
  Spin,
} from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import slugify from "react-slugify";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../ImageCrop";
import ImageField from "../Core/ImageField";

import { getList as packageList } from "../../Redux/Package/actions";
import { getList as teamList } from "../../Redux/Team/actions";
import moment from "moment";

//import "antd/dist/antd.css";
import TextArea from "antd/lib/input/TextArea";
import DashboardActionBar from "../DashboardActionBar";
import countryCode from "../../lib/country-code.json";
const { Option } = Select;

const TestimonialForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getPackageList,
  packageListData,
  getTeamList,
  teamListData,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [review, setReview] = useState(
    isUpdateForm && data.dataById?.data.review
  );
  const [avatar, setAvatar] = useState(
    isUpdateForm
      ? data.dataById &&
          data.dataById.data.avatar &&
          data.dataById.data.avatar.full_path
      : null
  );
  const [image_crop_open, setImageCropOpen] = useState(false);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading = selectedForm.loading || packageListData.loading; // || data.loading
  const [ratingValue, setRatingValue] = useState(
    isUpdateForm ? data.dataById?.data?.review_rating : 0
  );

  const loadPackages = async () => {
    await getPackageList(tokenData);
  };

  const loadTeam = async () => {
    await getTeamList(tokenData);
  };

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
    if (!packageListData.data) {
      loadPackages();
    }

    if (isUpdateForm) {
      setRatingValue(data.dataById?.data?.review_rating);
    }
  }, [packageListData.data]);

  useEffect(() => {
    if (!teamListData.data) {
      loadTeam();
    }
  }, [teamListData.data]);

  useEffect(() => {
    const alert = document.querySelector(".custom-alert");
    setTimeout(() => {
      alert.classList.add("fadeout");
    }, 5000);
  }, [selectedForm.error]);

  const countryNameList = countryCode.map((itm) => {
    return {
      label: itm.name,
      value: itm.name,
    };
  });

  const countryCodeList = countryCode.map((itm) => {
    return {
      label: itm.name,
      value: itm.code,
    };
  });

  const packageListOptions = packageListData.data?.data.map((itm) => {
    return {
      value: itm.id,
      label: itm.package_title,
    };
  });

  const teamListOptions = teamListData.data?.data.map((itm) => {
    return {
      value: itm.id,
      label: itm.full_name,
    };
  });

  // const [reviewTitle, setReviewTitle] = useState();
  // const [packageTitle, setPackageTitle] = useState();
  // const [reviewDate, setReviewDate] = useState();
  // const [reviewMember, setReviewMember] = useState();
  // const [country, setCountry] = useState();
  //const [fullname, setFullName] = useState();
  //const metaTitle = `${reviewTitle} | Reviewed By ${fullname}, ${country}`;
  //const metaDiscraption = `Review of Nepal Hiking Team, ${packageTitle}, ${reviewTitle}, Trip did in  ${reviewDate} with ${reviewMember}.`;

  // if (reviewTitle && fullname && country) {
  //   form.setFieldValue("meta_title", metaTitle);
  // }
  // if (reviewTitle && packageTitle && reviewDate && reviewMember) {
  //   form.setFieldValue("meta_description", metaDiscraption);
  //}
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  if (isUpdateForm) {
    setFieldsValue({
      salutation: data.dataById?.data.salutation,
      full_name: data.dataById?.data.full_name,
      url_title: data.dataById?.data.urlinfo?.url_title,
      slug: data.dataById?.data.urlinfo?.url_slug,
      package_id: data.dataById?.data.packages.map((a) => a.id),
      members: data.dataById?.data.members.map((a) => a.id),
      review_date: moment(data.dataById?.data.review_date),
      review_rating: data.dataById?.data.review_rating,
      reivew_abstract: data.dataById?.data.reivew_abstract,
      affiliate_organization: data.dataById?.data.affiliate_organization,
      organization_address: data.dataById?.data.organization_address,
      organization_position: data.dataById?.data.organization_position,
      organization_link: data.dataById?.data.organization_link,
      status: data.dataById?.data.status === 1 ? true : false,
      is_featured: data.dataById?.data.is_featured === 1 ? true : false,
      review: data.dataById?.data.review,
      meta_title: data.dataById?.data.meta?.meta_title,
      meta_keywords: data.dataById?.data.meta?.meta_keywords,
      meta_review: data.dataById?.data.meta?.meta_review,
      avatar: data.dataById?.data.avatar?.thumb_path,
      avatar_alt: data.dataById?.data.avatar?.avatar_alt,
      avatar_caption: data.dataById?.data.avatar?.avatar_caption,
      country_code: data.dataById?.data.country_code,
      country_name: data.dataById?.data.country_name,
      address: data.dataById?.data.address,
      review_abstract: data.dataById?.data.review_abstract,
    });
  }


  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const axios = require("axios");
      values.review_rating = ratingValue;
      values.review_date = formatDate(values.review_date);
      values.country_name = countryCode.filter(a => a.code === values.country_code)[0].name;
      values.is_featured = values.is_featured ? 1 : 0;
      values.status = values.status ? 1 : 0;
      if (action === "add") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            createEntity(
              {
                ...values,
                avatar: response.data,
              },
              tokenData
            );
          });
        } else {
          createEntity(
            {
              ...values,
            },
            tokenData
          );
        }
      } else if (action === "addandexit") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            createEntity(
              {
                ...values,
                avatar: response.data,
              },
              tokenData,
              true,
              history
            );
          });
        } else {
          createEntity(
            {
              ...values,
            },
            tokenData,
            true,
            history
          );
        }
      } else if (action === "update") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            updateEntityInfo(
              {
                ...values,
                avatar: response.data,
              },
              tokenData,
              params.id
            ).then(() => {
              loadEntityById();
            });
          });
        } else {
          updateEntityInfo(
            {
              ...values,
            },
            tokenData,
            params.id
          ).then(() => {
            loadEntityById();
          });
        }
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setReview("");
    setAvatar("");
  };

  const actionBarOptions = {
    titleToAdd: "Add Testimonial",
    titleToUpdate: "Update Testimonial",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Testimonial",
    taskCompleteAddMessage: "Testimonial is added!",
    taskCompleteUpdateMessage: "Testimonial is updated!",
    discardLink: "testimonials",
    pageUrl:
      isUpdateForm && "testimonial/" + data.dataById?.data.urlinfo?.url_slug,
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  const desc = ["Terrible", "Poor", "Average", "Very Good", "Excellent"];

  const handleRatingChange = (value) => {
    setRatingValue(value);
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module mt-3 bg-white">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Review Title"
                  name="url_title"
                  rules={[
                    {
                      required: true,
                      message: "Url title is required",
                    },
                    { min: 5, max: 255, message: "Invalid Url title" },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({ slug: slugify(e.target.value) });
                      //isAddForm && setReviewTitle(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label={
                    <span className="ant-form-item-optional">
                      Auto Generate from Url Title
                    </span>
                  }
                  name="slug"
                  title="Auto Generate from Url Title"
                  rules={[
                    {
                      required: true,
                      message: "Slug is required",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Row gutter={{ xs: 2.5, sm: 2.5, md: 2.5 }}>
                  <Col className="gutter-row" xs={6}>
                    <Form.Item
                      label="Full Name"
                      name="salutation"
                      rules={[
                        {
                          required: true,
                          message: "Salutation is required",
                        },
                      ]}
                    >
                      <Select>
                        <Option value="Mr.">Mr.</Option>
                        <Option value="Ms.">Ms.</Option>
                        <Option value="Mrs.">Mrs.</Option>
                        <Option value="Miss.">Miss.</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={18}>
                    <Form.Item
                      label="&nbsp;"
                      name="full_name"
                      className="noStar"
                      rules={[
                        {
                          required: true,
                          message: "Full name is required",
                        },
                        { min: 5, max: 255, message: "Invalid title" },
                      ]}
                    >
                      <Input  />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Row gutter={{ xs: 2.5, sm: 2.5, md: 2.5 }}>
                  <Col className="gutter-row" xs={10}>
                    <Form.Item
                      label="Address"
                      name="country_code"
                      rules={[
                        {
                          required: true,
                          message: "Select your country",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Country"
                        showSearch
                        //onChange={(e) => setCountry(e)}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={countryCodeList?.map((itm, idx) => {
                          return {
                            value: itm.value,
                            label: itm.label,
                          };
                        })}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={14}>
                    <Form.Item
                      label="&nbsp;"
                      name="address"
                      className="noStar"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Address is required",
                      //   },
                      // ]}
                    >
                      <Input placeholder="city, state, zip" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item label="Abstract" name="reivew_abstract">
                  <Input />
                </Form.Item>
              </Col>

              {/* <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item label="Select a packages" name="package_id">
                  <Select
                    mode="multiple"
                    showSearch
                    onChange={(e) =>
                      setPackageTitle(
                        packageListOptions?.filter(
                          (itm) => itm.value === e[0]
                        )[0].label
                      )
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={packageListOptions?.map((itm, idx) => {
                      return {
                        value: itm.value,
                        label: itm.label,
                      };
                    })}
                  ></Select>
                </Form.Item>
              </Col> */}

              {/* <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item label="Select a Team" name="members">
                  <Select
                    mode="multiple"
                    showSearch
                    onChange={(e) =>
                      setReviewMember(
                        teamListOptions?.filter((itm) => itm.value === e[0])[0]
                          .label
                      )
                    }
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={teamListOptions?.map((itm, idx) => {
                      return {
                        value: itm.value,
                        label: itm.label,
                      };
                    })}
                  />
                </Form.Item>
              </Col> */}

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Date"
                  name="review_date"
                  rules={[
                    {
                      required: true,
                      message: "Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    }
                    format="YYYY-MM-DD"
                    // onChange={(date, d) =>
                    //   setReviewDate(moment(d).format("MMM YYYY"))
                    // }
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item label="Credit" name="affiliate_organization">
                  <Select>
                    <Option label="Self" value="self">
                      Self
                    </Option>
                    <Option label="TripAdvisor" value="trip_advisor">
                      Trip Advisor
                    </Option>
                    <Option label="Facebook" value="facebook">
                      Facebook
                    </Option>
                    <Option label="Google" value="google">
                      Google
                    </Option>
                    <Option label="Trustpilot" value="Trust_pilot">
                      Trust Pilot
                    </Option>
                    <Option label="Others" value="others">
                      Others
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8} hidden>
                <Form.Item label="Company Address" name="organization_address">
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8} hidden>
                <Form.Item label="Designation" name="organization_position">
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8} hidden>
                <Form.Item
                  label="Website"
                  name="organization_link"
                  rules={[{ type: "url", message: "Invalid url" }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={24} xl={16}>
                <div className="switch-group">
                  <Form.Item label="Ratings" name="review_rating">
                    <span>
                      <Rate
                        tooltips={desc}
                        onChange={handleRatingChange}
                        value={ratingValue}
                      />
                      {ratingValue ? (
                        <span className="ant-rate-text">
                          {desc[ratingValue - 1]}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </Form.Item>
                  <Form.Item
                    label="Status"
                    valuePropName="checked"
                    name="status"
                  >
                    <Switch
                      checkedChildren="Publish"
                      unCheckedChildren="Unpublish"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Is Featured?"
                    valuePropName="checked"
                    name="is_featured"
                  >
                    <Switch checkedChildren="Yes" unCheckedChildren="No" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
          <div className="common-module pt-0">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} xl={16}>
                <Form.Item label="Description" name="review">
                  <TextEditor
                    value={review}
                    initialValue={isUpdateForm && review}
                    onEditorChange={(e) => {
                      setReview(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} xl={8}>
                <label className="form-label">Meta</label>
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <Form.Item
                      label="Meta Title"
                      name="meta_title"
                      rules={[
                        {
                          required: true,
                          message: "Meta title is required",
                        },
                        { min: 5, max: 255, message: "Invalid meta title" },
                      ]}
                    >
                      <Input  />
                    </Form.Item>

                    <Form.Item
                      label="Meta Keywords"
                      name="meta_keywords"
                      rules={[
                        {
                          min: 5,
                          max: 255,
                          message: "Invalid meta keywords",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className="mb-0"
                      label="Meta Description"
                      name="meta_description"
                    >
                      <TextArea
                        rows={8}
                        showCount
                        maxLength={170}
                        //disabled={isAddForm}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Col>

              <Col className="gutter-row" xs={24} xl={10}>
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <Form.Item>
                      <ImageField
                        title="Avatar"
                        image={
                          avatar ||
                          (isUpdateForm &&
                            data.dataById?.data.avatar?.full_path)
                        }
                        height={150}
                        width={150}
                        setImage={(image_data) => {
                          setAvatar(image_data);
                          setImageCropOpen(true);
                        }}
                      />
                      {avatar && image_crop_open && (
                        <ImageCrop
                          image={avatar}
                          height={150}
                          width={150}
                          setCroppedImage={setAvatar}
                          setImageCropOpen={setImageCropOpen}
                        />
                      )}
                    </Form.Item>
                    <Form.Item
                      style={{ marginTop: "-20px" }}
                      label="Alt Text"
                      name="avatar_alt"
                      rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Caption"
                      name="avatar_caption"
                      rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    packageListData: state.packages,
    teamListData: state.team,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPackageList: (tokenData) => dispatch(packageList(tokenData)),
    getTeamList: (tokenData) => dispatch(teamList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialForm);
