import { combineReducers } from "redux";
import author from "./Author/reducer";
import authorForm from "./AuthorForm/reducer";
import blog from "./Blog/reducer";
import blogForm from "./BlogForm/reducer";
import category from "./Category/reducer";
import categoryForm from "./CategoryForm/reducer";
import categoryType from "./CategoryType/reducer";
import categoryTypeForm from "./CategoryTypeForm/reducer";
import user from "./User/reducer";
import userForm from "./UserForm/reducer";
import userAuth from "./UserAuth/reducer";

import page from "./Page/reducer";
import pageForm from "./PageForm/reducer";

import testimonial from "./Testimonial/reducer";
import testimonialForm from "./TestimonialForm/reducer";

import settings from "./Settings/reducer";
import settingForm from "./SettingsForm/reducer";

import menuType from "./MenuType/reducer";
import menuTypeForm from "./MenuTypeForm/reducer";

import menu from "./Menu/reducer";
import menuForm from "./MenuForm/reducer";

import team from "./Team/reducer";
import teamForm from "./TeamForm/reducer";

import faqs from "./Faqs/reducer";
import faqsForm from "./FaqsForm/reducer";


import tripinfo from "./Tripinfo/reducer";
import tripinfoForm from "./TripinfoForm/reducer";

import itinerary from "./Itinerary/reducer";
import itineraryForm from "./ItineraryForm/reducer";

import booking from "./Booking/reducer";
import bookingForm from "./BookingForm/reducer";


import payment from "./Payment/reducer";
import paymentForm from "./PaymentForm/reducer";

import departure from "./Departure/reducer";
import departureForm from "./DepartureForm/reducer";

import album from "./Album/reducer";
import albumForm from "./AlbumForm/reducer";

import media from "./Media/reducer";
import mediaForm from "./MediaForm/reducer";

import comment from "./Comment/reducer";
import commentForm from "./CommentForm/reducer";


//HOTEL
import packages from "./Hotel/Package/reducer";
import packageForm from "./Hotel/PackageForm/reducer";
import rooms from "./Hotel/Room/reducer";
import roomForm from "./Hotel/RoomForm/reducer";

import amenities from "./Hotel/Amenities/reducer";
import amenitiesForm from "./Hotel/Amenities/reducer";

import serviceMenu from "./Hotel/ServiceMenu/reducer";
import serviceMenuForm from "./Hotel/ServiceMenuForm/reducer";
import serviceMenuType from "./Hotel/ServiceMenuType/reducer";
import serviceMenuTypeForm from "./Hotel/ServiceMenuTypeForm/reducer";

import logs from "./Logs/reducer";

const rootReducer = combineReducers({
  author: author,
  authorForm: authorForm,
  blog: blog,
  blogForm: blogForm,
  settings: settings,
  settingForm: settingForm,
  category: category,
  categoryForm: categoryForm,
  categoryType: categoryType,
  categoryTypeForm: categoryTypeForm,
  menuType: menuType,
  menuTypeForm: menuTypeForm,
  menu: menu,
  menuForm: menuForm,
  user: user,
  article: page,
  articleForm: pageForm,
  userForm: userForm,
  logs: logs,
  team: team,
  teamForm: teamForm,
  faqs: faqs,
  faqsForm: faqsForm,
  testimonial: testimonial,
  testimonialForm: testimonialForm,
  comment: comment,
  commentForm: commentForm,
  tripinfo: tripinfo,
  tripinfoForm: tripinfoForm,
  itineraries: itinerary,
  itineraryForm: itineraryForm,
  departure: departure,
  departureForm: departureForm,
  booking: booking,
  bookingForm: bookingForm,
  payment: payment,
  paymentForm: paymentForm,
  album: album,
  albumForm: albumForm,
  media: media,
  mediaForm: mediaForm,
  loginData: userAuth,  
  packages: packages,
  packageForm: packageForm,
  rooms: rooms,
  roomForm: roomForm,
  amenities:amenities,
  amenitiesForm:amenitiesForm,  
  serviceMenu: serviceMenu,
  serviceMenuForm: serviceMenuForm,
  serviceMenuType:serviceMenuType,
  serviceMenuTypeForm:serviceMenuTypeForm
});

export default rootReducer;
