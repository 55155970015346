import React from "react";
import { connect } from "react-redux";

import DashboardAdd from "../../../Components/Layouts/DashboardAdd";
import PackageForm from "../../../Components/Forms/Hotel/PackageForm";
import { createEntity, updateEntityInfo, setUpdatedRecentlyToFalse } from "../../../Redux/Hotel/PackageForm/actions";
import { getEntityById } from "../../../Redux/Hotel/Package/actions";

function Add({ useAuth, getEntityById, createEntity, updateEntityInfo, setUpdatedRecentlyToFalse, data, loginData, selectedForm }) {
  return (
    <DashboardAdd useAuth={useAuth}>
        <PackageForm
          isAddForm
          {...{getEntityById, createEntity, updateEntityInfo, setUpdatedRecentlyToFalse, data, loginData, selectedForm}}
        />
    </DashboardAdd>
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.package,
    selectedForm: state.packageForm,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEntityById: (tokenData, id) => dispatch(getEntityById(tokenData, id)),
    createEntity: (formData, tokenData, exitAfter, history) => dispatch(createEntity(formData, tokenData, exitAfter, history)),
    updateEntityInfo: (formData, tokenData, id) => dispatch(updateEntityInfo(formData, tokenData, id)),
    setUpdatedRecentlyToFalse: () => dispatch(setUpdatedRecentlyToFalse()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
