import React, { useContext } from "react";
import Avatar from "../assets/images/avatar.jpg";
import { Link, useHistory, useLocation } from "react-router-dom";
import { logoutUser } from "../Redux/UserAuth/actions";
import { connect } from "react-redux";
import GlobalContext from "../utils/GlobalContext";
import { BASE_URL } from "../Redux/urls";

function Toolbar({ toggleSidebar, logoutUser, useAuth, sidebarOpen }) {
  let history = useHistory();
  let auth = useAuth();
  const logged_in_user = auth.user;

  const globalData = useContext(GlobalContext);

  let timeout;

  function resetTimer() {
    clearTimeout(timeout);
    timeout = setTimeout(logout, 3600000); // set timeout for 1 hrs
  }

  function logout() {
    logoutUser();
    auth.signout(() => history.push("/dashboard"));
  }

  window.addEventListener("mousemove", resetTimer);
  window.addEventListener("keypress", resetTimer);

  return (
    <aside className="toolbar dark">
      <div className="toolbar-container">
        <nav>
          <ul>
            <li>
              <Link
                className={`sidebar-toggler ${sidebarOpen ? " in " : ""}`}
                onClick={() => toggleSidebar()}
              >
                <div className="toggle-btn">
                  <span>Menu</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/dashboard">
                <i className="bi bi-house-door"></i>
              </Link>
            </li>
          </ul>
        </nav>
        <nav>
          <ul>
            <li className="user-profile active">
              <Link to="/">
                <img
                  src={
                    globalData?.avatar
                      ? BASE_URL + "/" + globalData?.avatar
                      : Avatar
                  }
                />
              </Link>
            </li>
            {localStorage.admin_type === "super" && (
              <li>
                <Link to="/settings">
                  <i className="bi bi-gear"></i>
                </Link>
              </li>
            )}
            <li>
              <a href="#">
                <i className="bi bi-bell"></i>
              </a>
            </li>
            <li>
              <Link
                onClick={() => {
                  logoutUser();
                  auth.signout(() => history.push("/dashboard"));
                }}
              >
                <i className="bi bi-power"></i>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
