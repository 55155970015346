import React from "react";
import { connect } from "react-redux";
import DashboardList from "../../../Components/Layouts/DashboardList";
import { getList, deleteEntityById } from "../../../Redux/Hotel/ServiceMenuType/actions";

function List({ useAuth, getList, list, onDelete }) {
  const tableConfig = {
    title: "Cuisine types",
    applyFilter: false,
    tableColumns: [
      {name: "Status", selector: 'status', width: '3%'},
      {name: "Category Type", selector: "type_title", width: '77%'},
      {name: "Modified Date", selector: 'updated_at', width: '15%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    searchByColumn: 'type_title',   
    addPath: "/hotel/service-menu-type/add",
    updatePath: "/hotel/service-menu-type/update",
    noDataMessage: "No Cuisine type exists",
    deleteBtnText: 'Delete',
    updateLinkColumn: "type_title",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  }
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.serviceMenuType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),
    onDelete: (idList) => dispatch(deleteEntityById(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
