export const TRIPINFO_LIST_LOADING = "TRIPINFO_LIST_LOADING";
export const GET_TRIPINFO_LIST = "GET_TRIPINFO_LIST";
export const GET_TRIPINFO_LIST_ERROR = "GET_TRIPINFO_LIST_ERROR";
export const GET_TRIPINFO_BY_ID_LOADING = "GET_TRIPINFO_BY_ID_LOADING";
export const GET_TRIPINFO_BY_ID_SUCCESS = "GET_TRIPINFO_BY_ID_SUCCESS";
export const GET_TRIPINFO_BY_ID_ERROR = "GET_TRIPINFO_BY_ID_ERROR";

export const DELETEING_BLOG_TRIPINFO = "DELETEING_BLOG_TRIPINFO";
export const DELETE_BLOG_TRIPINFO = "DELETE_BLOG_TRIPINFO";
export const DELETE_BLOG_TRIPINFO_ERROR = "DELETE_BLOG_TRIPINFO_ERROR" 

export const DELETED_BLOG_TRIPINFO_LIST_LOADING = "DELETED_BLOG_TRIPINFO_LIST_LOADING";
export const GET_DELETED_BLOG_TRIPINFO_LIST = "GET_DELETED_BLOG_TRIPINFO_LIST";
export const GET_DELETED_BLOG_TRIPINFO_LIST_ERROR = "GET_DELETED_BLOG_TRIPINFO_LIST_ERROR";

export const RESTORE_BLOG_TRIPINFO_LIST_LOADING = "RESTORE_BLOG_TRIPINFO_LIST_LOADING";
export const RESTORE_BLOG_TRIPINFO_LIST = "RESTORE_BLOG_TRIPINFO_LIST";
export const RESTORE_BLOG_TRIPINFO_LIST_ERROR = "RESTORE_BLOG_TRIPINFO_LIST_ERROR";

export const PERMA_DELETEING_BLOG_TRIPINFO = "PERMA_DELETEING_BLOG_TRIPINFO";
export const PERMA_DELETE_BLOG_TRIPINFO = "PERMA_DELETE_BLOG_TRIPINFO";
export const PERMA_DELETE_BLOG_TRIPINFO_ERROR = "PERMA_DELETE_BLOG_TRIPINFO_ERROR" 
