
import React from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { getList } from "../../Redux/Album/actions";
import { deleteEntityById } from "../../Redux/Media/actions";
import clean  from "lodash-clean";
import Item from "antd/lib/list/Item";

function List({ useAuth, getList, list, onDelete}) {
  // const datatolist = list.data?.data.map(itm => {
  //   return {
  //     ...itm,
  //     ...(itm.content.length > 0 && {children: itm.content} )
  //   }
  // })
  
  const tableConfig = {
    title: "Media",
    filterOptionTitle: "Team Type",
    filterOptions: [
      "package",
    ],
    defaultFilterValue: 'admin',
    applyFilter: false,
    tableColumns: [
      {name: "Title", selector: 'title', width: '77%'},
      {name: "Status", selector: 'status', width: '3%'},
      {name: "Modified Date", selector: 'updated_at', width: '15%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'title',
    searchByColumn: 'title',
    updatePath: "/media/update",
    updateParentPath: "/album/update",
    addPath: "/media/add",
    noDataMessage: "No album exists",
    updateLinkColumn: 'title',
    deleteBtnText: 'Delete',
    deleteItemsByIdList: (idList) => {
      onDelete(idList)
    },
  }

  const datatolist =  {
      ...list,
      ...(list.data && {
        data: {
          ...list.data,
          ...(list.data?.data && {
            data: list.data?.data?.map(itm => {
              return{
                ...itm,
                ...(itm.content.length > 0 && {children: itm.content.map((el, idx) => {
                  return{
                    ...el,
                    title: el.caption?'--- '+el.caption:'--- Media Item'+ ' ' +( idx+1)
                  }
                })})
              }
            })
          })
        }
      }),
        dataById: list.dataById,
        error: list.error,
        loading: list.loading
  }

  // console.log(datatolist.data?.content?.map((itm, idx) => {
  //   return{
  //     ...itm,
  //     title: "Media Item " + idx+1
  //   }
  // }));
console.log(datatolist);
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={clean(datatolist)}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.album,  
    loginData: state.loginData, 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),
    onDelete: (idList) => dispatch(deleteEntityById(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
