import {
  CREATING_ALBUM,
  UPDATING_ALBUM,
  ALBUM_CREATED,
  ALBUM_UPDATED,
  ERROR_ALBUM_CREATE,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const setUpdatedRecentlyToFalse = () => async (dispatch) => {
  dispatch({
    type: SET_UPDATED_RECENTLY_TO_FALSE,
  });
}

export const createEntity = (formData, tokenData, exitNow, history) => async (dispatch) => {
  dispatch({
    type: CREATING_ALBUM,
  });

  let data = Object.fromEntries(Object.entries(formData).filter(([_, v]) => v != null).filter(([_, v]) => v !== "").filter(([_, v]) => v !== undefined));

  let webKitFormData = new FormData();

  Object.keys(data).forEach((item, i) => {
    webKitFormData.append(item, data[item])
  });

  await axios
    .post(`${BASE_URL}api/v1/admin/carousel/add`, webKitFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: ALBUM_CREATED,
        payload: response,
      });
      dispatch({
        type: UPDATED_RECENTLY,
      })      
      message.success(response.data.message);
      if (exitNow) {
        history.push("/albums")
      }
    })
    .catch(function (error) {
      dispatch({
        type: ERROR_ALBUM_CREATE,
        payload: error.response,
      })      
      message.error(error.response.data.message);
    });
};

export const updateEntityInfo = (formData, tokenData, id) => async (dispatch) => {
  dispatch({
    type: UPDATING_ALBUM,
  }); 
  console.log(formData);
  let data = Object.fromEntries(
    Object.entries(formData)
      .filter(([_, v]) => v != null)
      .filter(([_, v]) => v !== "")
      .filter(([_, v]) => v !== undefined)
  );
  let webKitFormData = new FormData();

  Object.keys(data).forEach((item, i) => {
    webKitFormData.append(item, data[item]);
  });
 webKitFormData.append("_method", "put");
  

  await axios
    .post(`${BASE_URL}api/v1/admin/carousel/edit/${id}`, webKitFormData, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: ALBUM_UPDATED,
        payload: response,
      });
      dispatch({
        type: UPDATED_RECENTLY,
      })      
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: ERROR_ALBUM_CREATE,
        payload: error.response,
      })      
      message.error(error.response.data.message);
    });
};
