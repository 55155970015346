import {
  USER_LIST_LOADING,
  GET_USER_LIST,
  GET_USER_LIST_ERROR,
  GET_USER_BY_ID_LOADING,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_ERROR,
  DELETED_USER_LIST_LOADING,
  GET_DELETED_USER_LIST,
  GET_DELETED_USER_LIST_ERROR,
  DELETEING_USER,
  DELETE_USER,
  DELETE_USER_ERROR,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const getList = (tokenData) => async (dispatch) => {
  dispatch({
    type: USER_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/member/all`, {
      headers: {
        Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: GET_USER_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_USER_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const getEntityById = (tokenData, id) => async (dispatch) => {
  dispatch({
    type: GET_USER_BY_ID_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/member/${id}`, {
      headers: {
        Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: GET_USER_BY_ID_SUCCESS,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_USER_BY_ID_ERROR,
        payload: error.response,
      });
    });
};

export const getDeletedList = (tokenData) => async (dispatch) => {
  dispatch({
    type: DELETED_USER_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/member/deleted`, {
      headers: {
        Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: GET_DELETED_USER_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_DELETED_USER_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const deleteEntityById = (idList) => async (dispatch) => {
  dispatch({
    type: DELETEING_USER,
  });
  let user = { bulk: idList };
  await axios
    .post(`${BASE_URL}api/v1/admin/member/bulk/delete`, user, {
      headers: {
        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: DELETE_USER,
        payload: response,
      });
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: DELETE_USER_ERROR,
        payload: error.response,
      })
      message.error(error.response.data.message);
    });
};
