import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import UserLogPageLayout from "../Components/LogPageLayout";
import LoginForm from "../Components/Forms/LoginForm";
import {
  loginUser,
  setUserLoginDataFromLocalStorage,
} from "../Redux/UserAuth/actions";
import { BASE_URL } from "../Redux/urls";
import axios from "axios";
import GlobalContext from "../utils/GlobalContext";

function Login({
  useAuth,
  loginUser,
  loginData,
  setUserLoginDataFromLocalStorage,
}) {
  const [globalData, setGlobalData] = useState();
  const getGlobalData = async () => {
    await axios
      .get(`${BASE_URL}api/v1/front/global`, {
        headers: { siteKey: process.env.REACT_APP_FRONT_SITE_KEY },
      })
      .then((res) => setGlobalData(res.data))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    // if (!globalData.data) {
    //   const res = await axios.get(`${BASE_URL}api/v1/front/global`, {headers: {siteKey: 'hTf8l3Euiw5lr9HgQ52mAi'}});
    //   const data = await res.data;
    //   setGlobalData(data.data);
    // }
    if (!globalData) {
      getGlobalData();
    }
  }, [globalData]);

  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  let { from } = location.state || { from: { pathname: "/dashboard" } };
  let loginClicked = (userCredentials, remember_me) => {
    loginUser(userCredentials, remember_me);
  };
  if (loginData.logged_in) {
    auth.signin(() => {
      history.replace(from);
    }, loginData);
  } else if (
    localStorage &&
    localStorage.getItem("access_token") &&
    localStorage.getItem("expires_at") &&
    localStorage.getItem("token_type")
  ) {
    setUserLoginDataFromLocalStorage();
  }

  //console.log(`Please login to access: ${from.pathname}`);
  if (loginData.logged_in) {
    // REDIRECTING
    return <div></div>;
  }

  return (
    <GlobalContext.Provider value={globalData?.data}>
      <UserLogPageLayout>
        <LoginForm loginClicked={loginClicked} loginData={loginData} />
      </UserLogPageLayout>
    </GlobalContext.Provider>
  );
}

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (userCredentials, remember_me) =>
      dispatch(loginUser(userCredentials, remember_me)),
    setUserLoginDataFromLocalStorage: () =>
      dispatch(setUserLoginDataFromLocalStorage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
